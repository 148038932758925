import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  openCustomerShppingAddressSelector,
  closeCustomerShppingAddressSelector,
  openCustomerBillingAddressSelector,
  closeCustomerBillingAddressSelector,
  setSelectedBillingAddress,
  setSelectedShippingAddress,
} from '../../../action/customer';
import CustomerDetailWrapper from '../presenters/CustomerDetailWrapper';
import {
  patchAddressRequest,
  insertAddressRequest,
  getAddress,
} from '../../../action/account';
import { insertContact } from '../../../action/contact';

const ShippingCustomerDetailContainer = ({
  selectedCustomer,
  customerAddress,
  openCustomerShppingAddressSelector,
  closeCustomerShppingAddressSelector,
  isCustomerShppingAddressSelectorOpen,
  selectedShippingAddress,
  setSelectedShippingAddress,
  patchAddressRequest,
  fields,
  insertAddress,
  dropShipContact,
}) => {
  return (
    <div>
      <CustomerDetailWrapper
        selectedCustomer={selectedCustomer}
        customerAddress={customerAddress}
        dropShipContact={dropShipContact}
        patchAddressRequest={patchAddressRequest}
        closeAddressSelector={closeCustomerShppingAddressSelector}
        isCustomerAddressSelectorOpen={isCustomerShppingAddressSelectorOpen}
        openCustomerAddressSelector={openCustomerShppingAddressSelector}
        header="Shipping Address"
        selectedAddress={selectedShippingAddress}
        setSelectedAddress={(address) => {
          fields.ship_to.input.onChange(address.id);
          setSelectedShippingAddress(address);
        }}
        insertAddressRequest={insertAddressRequest}
        insertContact={insertContact}
        fields={fields}
        type="shipping"
      />
    </div>
  );
};

export default connect(
  (state) => ({
    selectedCustomer: state.selectedCustomer.shippingCustomer,
    customerAddress: state.customerAddress.shippingAddress,
    isCustomerShppingAddressSelectorOpen:
      state.isCustomerShppingAddressSelectorOpen,
    selectedShippingAddress: state.selectedAddress.shippingAddress,
    dropShipContact: state.dropShipContact,
  }),
  (dispatch) => ({
    openCustomerShppingAddressSelector: bindActionCreators(
      openCustomerShppingAddressSelector,
      dispatch
    ),
    closeCustomerShppingAddressSelector: bindActionCreators(
      closeCustomerShppingAddressSelector,
      dispatch
    ),
    setSelectedShippingAddress: bindActionCreators(
      setSelectedShippingAddress,
      dispatch
    ),
    patchAddressRequest: bindActionCreators(patchAddressRequest, dispatch),
    insertAddressRequest: bindActionCreators(insertAddressRequest, dispatch),
    insertContact: bindActionCreators(insertContact, dispatch),
  })
)(ShippingCustomerDetailContainer);
