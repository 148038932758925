import React, { Component, PropTypes, PureComponent } from 'react';
import axios from 'axios';

const validationMessageStyle = {
  color: 'rgb(190, 18, 56)',
  position: 'absolute',
  top: '36px',
  fontWeight: 900,
};

const validationInputStyle = {
  border: 'solid 1px red',
};

const withReduxFormValidation = (Component) => {
  return class extends PureComponent {
    constructor(props) {
      super(props);
    }

    render() {
      const {
        input,
        meta: { touched, error, warning },
      } = this.props;
      return (
        <React.Fragment>
          <Component {...this.props} error={touched && error} />
          {touched &&
            ((error && (
              <span style={validationMessageStyle}>!! {error}</span>
            )) ||
              (warning && <span className="">! {warning}</span>))}
        </React.Fragment>
      );
    }
  };
};

export default withReduxFormValidation;
