import { SET_COUNTRY_LIST } from '../action/Country';

export const countryList = (state = [], action) => {
  switch (action.type) {
    case SET_COUNTRY_LIST:
      return action.countryList;
    default:
      return state;
  }
};
