import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { OutsideClickCloser } from '../../General/OutsideClickCloser';
import { getContactList } from '../../../action/contact';
import CustomerDropShipContactChangeMenu from '../presenters/CustomerDropShipContactChangeMenu';

class CustomerDropShipContactChangeMenuContainer extends Component {
  componentDidMount() {
    if (!this.props.contactList[this.props.AccountID]) {
      this.props.getContactList(this.props.AccountID);
    }
  }

  render() {
    const {
      contactList,
      AccountID,
      closeCustomerDropShipContactSelector,
      onContactSelect,
    } = this.props;

    return (
      <>
        <OutsideClickCloser onClick={closeCustomerDropShipContactSelector} />
        <CustomerDropShipContactChangeMenu
          contactList={contactList[AccountID]}
          closeCustomerDropShipContactSelector={
            closeCustomerDropShipContactSelector
          }
          onContactSelect={onContactSelect}
        />
      </>
    );
  }
}

export default connect(
  (state) => ({
    contactList: state.contactList,
  }),
  (dispatch) => ({
    getContactList: bindActionCreators(getContactList, dispatch),
  })
)(CustomerDropShipContactChangeMenuContainer);
