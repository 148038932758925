import React from 'react';
import { Label, Grid } from 'semantic-ui-react';
import { ColumnLabel, LabelInput } from './customComponents';
import withReduxFormValidation from '../../../HOCs/withReduxFormValidation';
import Input from 'semantic-ui-react/dist/commonjs/elements/Input/Input';

const SalesOrderNumber = (props) => {
  return <Input {...props} placeholder="number" />;
};

export default withReduxFormValidation(SalesOrderNumber);
