import { SET_COMPANY } from '../action/user';

export const company = (state = [], action) => {
  switch (action.type) {
    case SET_COMPANY:
      return action.company;
    default:
      return state;
  }
};
