import React from 'react';
import ModalWrapper from '../../General/ModalWrapper';
import AccountInfoContainer from '../containers/AccountInfoContainer';

export default function EditAccountBtn({ accountId }) {
  return (
    <ModalWrapper
      Trigger={(props) => (
        <a
          {...props}
          className="CustomerAddress__edit--button"
          style={{
            margin: 0,
          }}
        >
          Edit account
        </a>
      )}
      Contents={<AccountInfoContainer accountId={accountId} />}
    />
  );
}
