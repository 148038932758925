import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getCustomerList,
  getBillingCustomerAddress,
  setSelectedCustomer,
  closeCustomerShppingAddressSelector,
  setSelectedBillingAddress,
  setSelectedShippingAddress,
  getBillingCustomerDefaultAddress,
  getShippingCustomerAddress,
  getShippingCustomerDefaultAddress,
  setSelectedBillingCustomer,
  setSelectedShippingCustomer,
  closeCustomerBillingAddressSelector,
} from '../../../action/customer';
import CustomerSelector from '../presenters/CustomerSelector';

const propTypes = {};

const defaultProps = {};

function createWarning(funcName) {
  return () => console.warn((funcName = ' is not defined'));
}

class CustomerContainer extends PureComponent {
  componentWillMount() {
    if (this.props.customerList.length < 1) this.props.getCustomerList();
  }

  render() {
    return (
      <React.Fragment>
        {/* pass all props */}
        <CustomerSelector {...this.props} />
      </React.Fragment>
    );
  }
}

export default connect(
  (state) => ({ customerList: state.customerList }),
  (dispatch) => ({
    getCustomerList: bindActionCreators(getCustomerList, dispatch),
    getBillingCustomerAddress: bindActionCreators(
      getBillingCustomerAddress,
      dispatch
    ),
    getShippingCustomerAddress: bindActionCreators(
      getShippingCustomerAddress,
      dispatch
    ),
    getBillingCustomerDefaultAddress: bindActionCreators(
      getBillingCustomerDefaultAddress,
      dispatch
    ),
    getShippingCustomerDefaultAddress: bindActionCreators(
      getShippingCustomerDefaultAddress,
      dispatch
    ),
    setSelectedBillingCustomer: bindActionCreators(
      setSelectedBillingCustomer,
      dispatch
    ),
    setSelectedShippingCustomer: bindActionCreators(
      setSelectedShippingCustomer,
      dispatch
    ),
    setSelectedBillingAddress: bindActionCreators(
      setSelectedBillingAddress,
      dispatch
    ),
    setSelectedShippingAddress: bindActionCreators(
      setSelectedShippingAddress,
      dispatch
    ),
    closeCustomerBillingAddressSelector: bindActionCreators(
      closeCustomerBillingAddressSelector,
      dispatch
    ),
    closeCustomerShppingAddressSelector: bindActionCreators(
      closeCustomerShppingAddressSelector,
      dispatch
    ),
  })
)(CustomerContainer);

CustomerContainer.PropTypes = propTypes;
CustomerContainer.defaultProps = defaultProps;
