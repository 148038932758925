import Actions from '../action/index';
const {
  RECEIVE_SALESORDER_LIST,
  SET_INITAIL_SALESORDER_REDUX_FORM_VALUE,
  SET_IS_SALESORDER_FORM_DATA_LOADING,
  SET_IS_FORWARDER,
  SET_TEST_FILTER_ENABLE,
  SET_DATE_FILTER_ENABLE,
  SET_SHIPMENT_DATE_FILTER_ENABLE,
  SET_ISSODATESUPDATECOMPLETE,
  SET_PICKUP_DATE_FILTER_ENABLE,
} = Actions.salesOrder;

export const isForwarder = (state = false, action) => {
  switch (action.type) {
    case SET_IS_FORWARDER:
      return action.isForwarder;
    default:
      return state;
  }
};

export const salesOrderList = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_SALESORDER_LIST:
      return action.salesOrderList;
    default:
      return state;
  }
};

export const salesOrderFormValue = (state = {}, action) => {
  switch (action.type) {
    case SET_INITAIL_SALESORDER_REDUX_FORM_VALUE:
      return action.salesOrderForm;
    default:
      return state;
  }
};

export const isSalesOrderFormDataLoading = (state = false, action) => {
  switch (action.type) {
    case SET_IS_SALESORDER_FORM_DATA_LOADING:
      return action.isSalesOrderFormDataLoading;
    default:
      return state;
  }
};

export const isTestFilterChecked = (state = false, action) => {
  switch (action.type) {
    case SET_TEST_FILTER_ENABLE:
      return action.isTestChecked;
    default:
      return state;
  }
};

export const isDatePickerPicked = (state = false, action) => {
  switch (action.type) {
    case SET_DATE_FILTER_ENABLE:
      console.log('reducer : ', action.dateForFilter);
      return action.dateForFilter;
    default:
      return state;
  }
};

export const isShipmentDatePicked = (state = false, action) => {
  switch (action.type) {
    case SET_SHIPMENT_DATE_FILTER_ENABLE:
      console.log('reducer : ', action.dateForFilterShipment);
      return action.dateForFilterShipment;
    default:
      return state;
  }
};

export const isPickUpDatePicked = (state = false, action) => {
  switch (action.type) {
    case SET_PICKUP_DATE_FILTER_ENABLE:
      return action.dateForFilterPickUp;
    default:
      return state;
  }
};

export const isSODatesUpdateComplete = (state = false, action) => {
  switch (action.type) {
    case SET_ISSODATESUPDATECOMPLETE:
      return action.isSODatesUpdateComplete;
    default:
      return state;
  }
};
