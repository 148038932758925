// country codes that have states
// https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2#Officially_assigned_code_elements
export const COUNTRY_CODES_HAS_STATES = [
  'US',
  'CA',
  'AU',
  'BR',
  'CN',
  'IN',
  'JP',
  'MX',
  'RU',
  'ZA',
];

// check if country has state
export const isStateRequiredCountry = (countryCode) => {
  return COUNTRY_CODES_HAS_STATES.includes(countryCode);
};
