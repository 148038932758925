import React, { Component } from 'react';
import { Input } from 'semantic-ui-react';
import CustomerContactMenuItem from './CustomerContactMenuItem';

class CustomerDropShipContactChangeMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: '',
    };
  }

  onSearchChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const { contactList, onContactSelect } = this.props;

    const searchValue = this.state.searchValue;
    const contacts = (contactList || []).filter((contact) =>
      contact.name.toLowerCase().includes(searchValue.toLowerCase())
    );

    const contactItems = contacts.map((item) => (
      <CustomerContactMenuItem
        {...item}
        key={item.id}
        onClick={() => onContactSelect(item)}
      />
    ));

    return (
      <div className="DropShipContact__Edit--Container">
        <Input
          value={searchValue}
          onChange={this.onSearchChange}
          name="searchValue"
          placeholder="Search contacts..."
        />
        <div className="DropShipContact__Edit--Scroller">{contactItems}</div>
      </div>
    );
  }
}

export default CustomerDropShipContactChangeMenu;
