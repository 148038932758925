import React, { Component } from 'react';

import {
  Grid,
  Container,
  Header,
  Button,
  Dropdown,
  Checkbox,
  Label,
} from 'semantic-ui-react';
import axios from '../../util/CustomAxios';
import config from '../../config';
import * as Msal from 'msal';

import UserPng from '../../images/user.png';
import Statistics_BarTable from '../Statistics/Statistics_BarTable';

let authToken;

const CurrencyData = [
  {
    text: 'USD',
    value: 'USD',
  },
  {
    text: 'KRW',
    value: 'KRW',
  },
];
const TypeData = [
  {
    text: 'Compare',
    value: 'Compare',
  },
  {
    text: 'Amount',
    value: 'Amount',
  },
  {
    text: 'Sales Order',
    value: 'Sales Order',
  },
];

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTestShow: false,
      isLoginSuccessed: false,
    };
    this.clientApplication = new Msal.UserAgentApplication(
      config.graphConfig.clientID,
      null,
      function (errorDesc, token, error, tokenType) {
        // Called after loginRedirect or acquireTokenPopup
      }
    );

    this.graphClient = null;
    this.logout = this.logout.bind(this);
    this.requestLogin = this.requestLogin.bind(this);
    this.processAuth = this.processAuth.bind(this);
    this.onTestFilterClickEventHandler =
      this.onTestFilterClickEventHandler.bind(this);
  }

  processAuth() {
    const authToken = localStorage.token;
    if (localStorage.getItem('user') === null) {
      // Get the profile of the current user.
      this.getUserInfo().then(function (user) {
        // Save the user to localStorage.
        localStorage.setItem('user', user);
      });
    } else {
      let user = localStorage.user;
    }
  }

  logout() {
    // const clientApplication = this.clientApplication;

    // clientApplication.logout();

    this.setState({
      isLoginSuccessed: false,
    });
    delete localStorage.APIToken;
    delete localStorage.UserInfo;
    window.location.reload();
  }

  serverLogin(token) {
    axios.post(config.host + '/api/Login', { token: token }).then((res) => {
      localStorage.setItem('APIToken', res.data.token);
      localStorage.setItem('UserInfo', JSON.stringify(res.data.user));
      localStorage.setItem('Company', res.data.user.preferedCompany);
      localStorage.setItem('NowCompany', null);
      axios.defaults.headers.common['x-access-token'] = res.data.token;
      window.location.reload();
    });
  }

  requestLogin() {
    const clientApplication = this.clientApplication;

    clientApplication.loginPopup(config.graphConfig.graphScopes).then(
      (idToken) => {
        clientApplication
          .acquireTokenSilent(config.graphConfig.graphScopes)
          .then(
            (accessToken) => {
              localStorage.token = accessToken;
              // window.location.reload();
              this.serverLogin(accessToken);
            },
            (error) => {
              clientApplication
                .acquireTokenPopup(config.graphConfig.graphScopes)
                .then(
                  (accessToken) => {
                    localStorage.token = accessToken;
                    this.serverLogin(accessToken);
                  },
                  function (error) {
                    window.alert('Error acquiring the popup:\n' + error);
                  }
                );
            }
          );
      },
      function (error) {
        window.alert('Error during login:\n' + error);
      }
    );
  }

  componentWillMount() {
    if (localStorage.getItem('APIToken')) {
      axios
        .get(`/Main/CheckLoginToken`)
        .then((response) => {
          if (response.data == true) {
            this.setState({
              isLoginSuccessed: true,
            });
          } else {
            localStorage.clear();
            this.setState({
              isLoginSuccessed: false,
            });
          }
        })
        .catch(function (error) {
          localStorage.clear();
          console.log(error);
        });
    }
  }

  onTestFilterClickEventHandler(event, data) {
    console.log(data.value);
    this.setState({
      isTestShow: !this.state.isTestShow,
    });
  }

  render() {
    return (
      <Container>
        <Header as="h1" dividing>
          <span>Main</span>
          {this.state.isLoginSuccessed ? (
            <Button onClick={this.logout} style={{ float: 'right' }}>
              logout
            </Button>
          ) : (
            <Button onClick={this.requestLogin} style={{ float: 'right' }}>
              Login
            </Button>
          )}
          {this.state.isLoginSuccessed ? (
            <Checkbox
              style={{ left: '900px' }}
              label={<label>test</label>}
              value={this.state.isTestShow}
              onClick={this.onTestFilterClickEventHandler}
            ></Checkbox>
          ) : null}
        </Header>
        <br />
        {this.state.isLoginSuccessed ? (
          <Statistics_BarTable isTestShow={this.state.isTestShow} />
        ) : (
          <div>
            <img
              src={UserPng}
              height="400"
              width="400"
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '14%',
                display: 'block',
              }}
            />
            <br />
            <br />
            <h1 style={{ fontSize: '50px', textAlign: 'Center' }}>
              Login Required
            </h1>
          </div>
        )}
      </Container>
    );
  }
}

export default Main;
