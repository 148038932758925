import Actions from '../action/index';
import {
  INIT_CUSTOMER_ADDRESS,
  SET_SELECTED_BILLING_ADDRESS,
  SET_SELECTED_SHIPPING_ADDRESS,
  OPEN_CUSTOMER_BILLING_ADDRESS_SELECTOR,
  CLOSE_CUSTOMER_BILLING_ADDRESS_SELECTOR,
  INIT_SELECTED_ADDRESS,
  SET_BILLING_CUSTOMERID,
  RECEIVE_BILLING_CUSTOMER_ADDRESS,
  RECEIVE_SHIPPING_CUSTOMER_ADDRESS,
  SET_SELECTED_BILLING_CUSTOMER,
  SET_SELECTED_SHIPPING_CUSTOMER,
  SET_DROPSHIP_CONTACT,
  RESET_DROPSHIP_CONTACT,
} from '../action/customer';
const {
  REQUEST_CUSTOMER_LIST,
  RECEIVE_CUSTOMER_LIST,
  RECEIVE_CUSTOMER_ADDRESS,
  RECEIVE_CUSTOMER_SHPPING_ADDRESS,
  OPEN_CUSTOMER_SHPPING_ADDRESS_SELECTOR,
  CLOSE_CUSTOMER_SHPPING_ADDRESS_SELECTOR,
  SET_SELECTED_CUSTOMER,
  SET_VOLTAGE,
  SET_BILLING_COUNTRY,
  SET_SHIPPING_CUSTOMERID,
} = Actions.customer;

export const customerList = (state = [], action) => {
  switch (action.type) {
    case REQUEST_CUSTOMER_LIST:
      return state;
    case RECEIVE_CUSTOMER_LIST:
      return action.customerList;
    default:
      return state;
  }
};

export const selectedCustomer = (
  state = {
    billingCustomer: {},
    shippingCustomer: {},
  },
  action
) => {
  switch (action.type) {
    case SET_SELECTED_BILLING_CUSTOMER:
      return {
        ...state,
        billingCustomer: action.customer,
      };
    case SET_SELECTED_SHIPPING_CUSTOMER:
      return {
        ...state,
        shippingCustomer: action.customer,
      };
    default:
      return state;
  }
};

export const voltage = (state = '', action) => {
  switch (action.type) {
    case SET_VOLTAGE:
      return action.voltage;
    default:
      return state;
  }
};

export const billingCountry = (state = {}, action) => {
  switch (action.type) {
    case SET_BILLING_COUNTRY:
      return action.billingCountry;
    default:
      return state;
  }
};

export const customerAddress = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_BILLING_CUSTOMER_ADDRESS:
      return {
        ...state,
        billingAddress: action.customerAddress,
      };
    case RECEIVE_SHIPPING_CUSTOMER_ADDRESS:
      return {
        ...state,
        shippingAddress: action.customerAddress,
      };
    case INIT_CUSTOMER_ADDRESS:
      return {};
    default:
      return state;
  }
};

export const shippingCustomerID = (state = -1, action) => {
  switch (action.type) {
    case SET_SHIPPING_CUSTOMERID:
      return action.ID;
    default:
      return state;
  }
};

export const billingCustomerID = (state = -1, action) => {
  switch (action.type) {
    case SET_BILLING_CUSTOMERID:
      return action.ID;
    default:
      return state;
  }
};

export const isCustomerShppingAddressSelectorOpen = (state = false, action) => {
  switch (action.type) {
    case OPEN_CUSTOMER_SHPPING_ADDRESS_SELECTOR:
      return action.isOpen;
    case CLOSE_CUSTOMER_SHPPING_ADDRESS_SELECTOR:
      return action.isOpen;
    default:
      return state;
  }
};

export const isCustomerBillingAddressSelectorOpen = (state = false, action) => {
  switch (action.type) {
    case OPEN_CUSTOMER_BILLING_ADDRESS_SELECTOR:
      return action.isOpen;
    case CLOSE_CUSTOMER_BILLING_ADDRESS_SELECTOR:
      return action.isOpen;
    default:
      return state;
  }
};

export const selectedAddress = (state = {}, action) => {
  switch (action.type) {
    case SET_SELECTED_BILLING_ADDRESS:
      return {
        ...state,
        billingAddress: action.address,
      };
    case SET_SELECTED_SHIPPING_ADDRESS:
      return {
        ...state,
        shippingAddress: action.address,
      };
    case INIT_SELECTED_ADDRESS:
      return {};
    default:
      return state;
  }
};

export const dropShipContact = (state = null, action) => {
  switch (action.type) {
    case SET_DROPSHIP_CONTACT:
      return action.contact;
    case RESET_DROPSHIP_CONTACT:
      return {
        ...state,
        dropShipContact: null,
      };
    default:
      return state;
  }
};
