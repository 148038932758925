import React, { Component } from 'react';
import {
  Checkbox,
  Input,
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Image,
  Menu,
  Segment,
} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import SeattleImage from '../../images/Seattle.jpg';
import '../../styles/App.css';

class InAndOut extends Component {
  render() {
    return (
      <div className="Page-container">
        <Container>
          <Header as="h2" dividing>
            IN AND OUT LET'S GET IT
          </Header>
          <Header as="h5" dividing>
            Select Product
          </Header>
          <Grid columns={5} doubling>
            <Grid.Column>
              <Dropdown
                placeholder="Select Model"
                fluid
                search
                selection
                options={[
                  { key: '1', name: 'A10', text: 'A10' },
                  { key: '2', name: 'N10', text: 'N10' },
                  { key: 'X100', name: 'X100', text: 'X100' },
                ]}
              />
            </Grid.Column>
            <Grid.Column>
              <Dropdown
                placeholder="Select Color"
                fluid
                search
                selection
                options={[
                  { key: '1', name: 'link-1', text: 'Gold' },
                  { key: '2', name: 'link-2', text: 'Black' },
                  {
                    key: '3',
                    name: 'link-3',
                    text: 'Silver',
                  },
                ]}
              />
            </Grid.Column>
            <Grid.Column>
              <Dropdown
                placeholder="Select Voltage"
                fluid
                search
                selection
                options={[
                  { key: '1', name: 'link-1', text: '110' },
                  { key: '2', name: 'link-2', text: '220' },
                ]}
              />
            </Grid.Column>
            <Grid.Column>
              <Dropdown
                placeholder="Select HDD"
                fluid
                search
                selection
                options={[
                  { key: '1', name: 'link-1', text: '2TB' },
                  { key: '2', name: 'link-2', text: '4TB' },
                  { key: '3', name: 'link-3', text: '8TB' },
                ]}
              />
            </Grid.Column>
            <Grid.Column>
              <Dropdown
                placeholder="Select SSD"
                fluid
                search
                selection
                options={[
                  { key: '1', name: 'link-1', text: '1GB' },
                  { key: '2', name: 'link-2', text: '2GB' },
                  { key: '3', name: 'link-3', text: '3GB' },
                ]}
              />
            </Grid.Column>
          </Grid>
          <Header as="h5" dividing>
            Additional Info
          </Header>
          <Grid columns={3} padded="horizontallyvertically" doubling>
            <Grid.Column>
              <Input label="SO -" placeholder="S/O number" />
            </Grid.Column>
            <Grid.Column>
              <Input placeholder="Serial Number" />
            </Grid.Column>
            <Grid.Column>
              <Checkbox label="Sales OK" />
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default InAndOut;
