import React from 'react';
import '../SalesOrder.css';

const CustomerAddress = ({
  header,
  address,
  state,
  city,
  postal_code,
  countryName,
  cutomerName = '',
  accountName,
  isEditable,
  isAddable,
  editOnClick,
  addOnClick,
  attention_input,
}) => {
  return (
    <div className="CustomerAddress">
      <h5 className="CustomerAddress__Header">{header}</h5>
      {isEditable ? (
        <a
          className="CustomerAddress__edit--button"
          onClick={(e) => {
            editOnClick(e);
          }}
        >
          edit
        </a>
      ) : null}
      {isAddable ? (
        <a
          className="CustomerAddress__add--button"
          onClick={(e) => {
            addOnClick(e);
          }}
        >
          add
        </a>
      ) : null}
      <p className="CustomerAddress__Contents">
        <strong className="CustomerAddress__AccountName">{accountName}</strong>
      </p>
      <p className="CustomerAddress__Contents">{attention_input}</p>
      <p className="CustomerAddress__Contents">{address}</p>
      <p className="CustomerAddress__Contents">
        {city + ' '}
        {state}
        {postal_code && postal_code.replace(' ', '') != ''
          ? ', ' + postal_code
          : null}
      </p>
      <p className="CustomerAddress__Contents">{countryName}</p>
    </div>
  );
};

export default CustomerAddress;
