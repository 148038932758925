import Actions from '../action/index';
const {
  RECEIVE_PRODUCT_LIST,
  ADD_ORDER_PRODUCT,
  DELETE_ORDER_PRODUCT,
  SET_DISCOUNT,
  SET_CUSTOM_DISCOUNT,
  SET_SETTING_DISCOUNT,
  SET_QUANTITY,
  SET_INITIAL_PRODUCT_ORDER_LIST,
} = Actions.product;

export const productList = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_PRODUCT_LIST:
      return action.productList;
    default:
      return state;
  }
};

export const productOrderList = (state = [], action) => {
  switch (action.type) {
    case ADD_ORDER_PRODUCT:
      return [
        ...state,
        {
          id: action.id,
          product: action.product,
          productModel: action.productModel,
          quantity: action.quantity,
          discount: action.discount,
          isCustomDiscount: action.isCustomDiscount,
          SOIID: action.SOIID,
          serials: action.serials,
        },
      ];
    case DELETE_ORDER_PRODUCT:
      return state.filter((productOrder) => {
        if (productOrder.id === action.id) return false;
        return true;
      });
    case SET_DISCOUNT:
      return state.map((productOrder) =>
        productOrder.isCustomDiscount
          ? productOrder
          : {
              ...productOrder,
              discount: action.discount,
            }
      );
    case SET_CUSTOM_DISCOUNT:
      return state.map((productOrder) =>
        productOrder.id === action.id
          ? {
              ...productOrder,
              discount: action.discount,
              isCustomDiscount: true,
            }
          : productOrder
      );
    case SET_SETTING_DISCOUNT:
      return state.map((productOrder) =>
        productOrder.id === action.id
          ? {
              ...productOrder,
              discount: action.discount,
            }
          : productOrder
      );
    case SET_QUANTITY:
      return state.map((productOrder) =>
        productOrder.id === action.id
          ? { ...productOrder, quantity: action.quantity }
          : productOrder
      );
    case SET_INITIAL_PRODUCT_ORDER_LIST:
      return [];
    default:
      return state;
  }
};

export const discount = (state = 0, action) => {
  switch (action.type) {
    case SET_DISCOUNT:
      return action.discount;
    default:
      return state;
  }
};
