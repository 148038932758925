import React from 'react';

const styles = {
  popup: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  popupInner: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '5px',
    maxWidth: '500px',
    width: '100%',
  },
  formGroup: {
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
  },
  input: {
    width: '100%',
    padding: '5px',
    fontSize: '14px',
  },
  errorInput: {
    border: '1px solid red',
  },
  button: {
    marginRight: '10px',
    padding: '5px 10px',
    fontSize: '14px',
    cursor: 'pointer',
  },
};

const ContactInputModal = ({
  newContact,
  validationError,
  onNewContactChange,
  onNewContactSubmit,
  onCancel,
}) => {
  const { name, title, email, phone, note } = newContact;

  const normalInput = (value, name, error) => (
    <input
      style={
        error ? { border: 'solid 1px red', width: '100%' } : { width: '100%' }
      }
      value={value}
      name={name}
      onChange={onNewContactChange}
    />
  );

  return (
    <div style={styles.popup}>
      <div style={styles.popupInner}>
        <h2>New Contact</h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onNewContactSubmit();
          }}
        >
          <div style={styles.formGroup}>
            <label style={styles.label}>name</label>
            {normalInput(name, 'name', validationError.name)}
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label}>title</label>
            {normalInput(title, 'title', validationError.title)}
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label}>email</label>
            {normalInput(email, 'email', validationError.email)}
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label}>Phone</label>
            {normalInput(phone, 'phone', validationError.phone)}
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label}>Note</label>
            {normalInput(note, 'note', validationError.note)}
          </div>
          <button style={styles.button} type="submit">
            Add
          </button>
          <button style={styles.button} type="button" onClick={onCancel}>
            Cancel
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactInputModal;
