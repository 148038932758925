import {
  GET_SALES_STATISTICS_DATA,
  GET_SELECTED_SALES_STATISTICS_COLUMN,
  GET_PRODUCT_STATISTICS_DATA,
  GET_SELECTED_PRODUCT_STATISTICS_COLUMN,
} from '../action/statistics';

export const salesStatisticsData = (state = [], action) => {
  switch (action.type) {
    case GET_SALES_STATISTICS_DATA:
      return action.data;
    default:
      return state;
  }
};

export const selectedSalesStatisticsColumn = (state = {}, action) => {
  switch (action.type) {
    case GET_SELECTED_SALES_STATISTICS_COLUMN:
      return {
        month: action.month,
        status: action.status,
        currency: action.currency,
      };
    default:
      return state;
  }
};

export const productStatisticsData = (state = [], action) => {
  switch (action.type) {
    case GET_PRODUCT_STATISTICS_DATA:
      return action.data;
    default:
      return state;
  }
};

export const selectedProductStatisticsColumn = (state = {}, action) => {
  switch (action.type) {
    case GET_SELECTED_PRODUCT_STATISTICS_COLUMN:
      return {
        month: action.month,
        model: action.model,
      };
    default:
      return state;
  }
};
