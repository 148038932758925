import axios from '../util/CustomAxios';
export const IS_EXPECT_TEST_SALES_ORDER = 'IS_EXPECT_TEST_SALES_ORDER';
export const GET_SALES_STATISTICS_DATA = 'GET_SALES_STATISTICS_DATA';
export const GET_SELECTED_SALES_STATISTICS_COLUMN =
  'GET_SELECTED_SALES_STATISTICS_COLUMN';
export const GET_PRODUCT_MODEL_DATA = 'GET_PRODUCT_MODEL_DATA';
export const GET_PRODUCT_STATISTICS_DATA = 'GET_PRODUCT_STATISTICS_DATA';
export const GET_SELECTED_PRODUCT_STATISTICS_COLUMN =
  'GET_SELECTED_PRODUCT_STATISTICS_COLUMN';

export const getSalesStatisticsData =
  (company, year, accountID) => (dispatch) => {
    return axios
      .get('/SalesStatistics', {
        params: {
          Company: company,
          Year: year,
          AccountID: accountID == undefined ? null : accountID,
        },
      })
      .then((response) => {
        dispatch(receiveSalesStatisticsData(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
export function receiveSalesStatisticsData(salesStatisticsData) {
  return {
    type: GET_SALES_STATISTICS_DATA,
    data: salesStatisticsData,
  };
}
export function getSelectedSalesStatistisColumn(month, status, currency) {
  return {
    type: GET_SELECTED_SALES_STATISTICS_COLUMN,
    month: month,
    status: status,
    currency: currency,
  };
}

export const getProductStatisticsData =
  (company, year, accountID) => (dispatch) => {
    return axios
      .get('/ProductStatistics', {
        params: {
          Company: company,
          Year: year,
          AccountID: accountID == undefined ? null : accountID,
        },
      })
      .then((response) => {
        dispatch(receiveProductStatisticsData(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
export function receiveProductStatisticsData(productStatisticsData) {
  return {
    type: GET_PRODUCT_STATISTICS_DATA,
    data: productStatisticsData,
  };
}
export function getSelectedProductStatistisColumn(month, model) {
  return {
    type: GET_SELECTED_PRODUCT_STATISTICS_COLUMN,
    month: month,
    model: model,
  };
}
