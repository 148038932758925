import React from 'react';
import './ClickableSpan.css';

const ClickableSpan = ({ text, onClick, style }) => (
  <span className="clickable-span" onClick={onClick} style={style}>
    {text}
  </span>
);

export default ClickableSpan;
