import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import './ModalWrapper.css';

const propTypes = {};

const defaultProps = {};

class ModalWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.onOpenModal = this.onOpenModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  render() {
    console.log(this.props.Header);
    return (
      <div>
        <this.props.Trigger
          onClick={(event) => {
            this.onOpenModal();
          }}
        />
        <Modal
          open={this.state.open}
          onClose={this.onCloseModal}
          closeIcon={10}
        >
          <br />
          {this.props.Header}
          {this.props.Contents}
        </Modal>
      </div>
    );
  }
}

ModalWrapper.PropTypes = propTypes;
ModalWrapper.defaultProps = defaultProps;
export default ModalWrapper;
