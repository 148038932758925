import axios from '../util/CustomAxios';
export const SET_STATE_LIST = 'SET_STATE_LIST';

export function setStatetList(stateList) {
  return {
    type: SET_STATE_LIST,
    stateList: stateList,
  };
}

export const getStateList = (state) => (dispatch) => {
  return axios
    .get('/State')
    .then((response) => {
      dispatch(setStatetList(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
};
