import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import withReduxFormValidation from '../../../HOCs/withReduxFormValidation';

const CustomerAddressMenuItem = ({
  countryName,
  state,
  postal_code,
  address,
  active,
  onClick,
  attention_input,
}) => {
  const addressStyle = active
    ? 'CustomerAddress__Contents'
    : 'CustomerAddress__Contents CustomerAddress__Contents--Inactive';
  return (
    <div className="CustomerAddress__Contents--Container" onClick={onClick}>
      {/* attention input */}
      <p
        className={addressStyle}
        style={{
          color: 'blue',
        }}
      >
        {attention_input}
      </p>
      <p className={addressStyle}>{address}</p>
      <p className={addressStyle}>
        {state}
        {postal_code && postal_code.replace(' ', '') != ''
          ? ', ' + postal_code
          : null}
      </p>
      <p className={addressStyle}>{countryName}</p>
    </div>
  );
};

export default CustomerAddressMenuItem;
