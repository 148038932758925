import React, { Component, PropTypes } from 'react';
import axios from 'axios';
import { Unauthorized } from '../component/General/Unauthorized';
import ClickableSpan from '../component/General/ClickableSpan';

const withSaveAndUndo = (Component) => {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        value: this.props.value,
        prevValue: this.props.value,
      };
    }

    componentWillReceiveProps(nextProps) {
      this.setState({
        value: nextProps.value,
        prevValue: nextProps.value,
      });
    }

    onSubmitChange = (value) => {
      this.props.onChange(value);
    };

    onChange = (value) => {
      this.setState({
        value: value,
      });
    };

    undoChange = () => {
      this.setState({
        value: this.props.value,
        prevValue: this.props.value,
      });
    };

    render() {
      return (
        <React.Fragment>
          <Component
            {...this.props}
            value={this.state.value}
            onChange={this.onChange}
          />
          <span>
            {this.state.value !== this.state.prevValue ? (
              <span>
                <ClickableSpan
                  text="Click here to save change"
                  onClick={() => {
                    this.onSubmitChange(this.state.value);
                  }}
                />
                <ClickableSpan text="Undo" onClick={this.undoChange} />
              </span>
            ) : null}
          </span>
        </React.Fragment>
      );
    }
  };
};

export default withSaveAndUndo;
