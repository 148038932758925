import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Grid, Input, Button, Dropdown, Segment } from 'semantic-ui-react';
import ProductSuggestBox from '../presenters/ProductSuggestBox';
import ProductSuggestDropDown from '../presenters/ProductSuggestDropDown';
import ChangeIcon from '../../../images/shuffle.png';

const propTypes = {
  onAdd: PropTypes.func,
  discount: PropTypes.number,
};

const defaultProps = {
  onAdd: createWarning('onAdd'),
};

function createWarning(funcName) {
  return () => console.warn((funcName = ' is not defined'));
}

class ProductSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSingleSearchMode: false,
      isSelectItemUpdate: false,
      isButtonClicked: false,
      isAdded: false,
      seletedItem: null,
      isWrongSelect: false,
      isWrongQuantity: false,
      quantity: 1,
      openAtEnterPress: false,
    };
    this.changeModeButtonOnClickHandler =
      this.changeModeButtonOnClickHandler.bind(this);
    this.quantityInputOnChangeHandler =
      this.quantityInputOnChangeHandler.bind(this);
    this.quantityInputOnKeyPressHandler =
      this.quantityInputOnKeyPressHandler.bind(this);
    this.getSelecteditem = this.getSelecteditem.bind(this);
    this.getIsWrongSelect = this.getIsWrongSelect.bind(this);
  }

  changeModeButtonOnClickHandler() {
    this.setState({
      isSingleSearchMode: !this.state.isSingleSearchMode,
    });
  }

  quantityInputOnChangeHandler(event, data) {
    this.setState({
      quantity: event.target.value,
      isWrongQuantity: false,
    });
  }

  quantityInputOnKeyPressHandler(event, data) {
    if (event.key == 'Enter') {
      if (this.state.quantity > 0) {
        if (
          this.state.seletedItem != null &&
          this.state.seletedItem.length == 1
        ) {
          this.props.onAdd(
            this.state.seletedItem[0],
            this.state.quantity,
            this.props.discount,
            false
          );
          this.setState({
            seletedItem: null,
            isButtonClicked: true,
            isWrongSelect: false,
            openAtEnterPress: true,
          });
        } else {
          this.setState({
            isWrongSelect: true,
          });
        }
      } else {
        this.setState({
          isWrongQuantity: true,
        });
      }
    }
  }

  getSelecteditem(productList) {
    this.setState({
      seletedItem: productList,
      isSelectItemUpdate: true,
    });
    console.log(productList);
  }
  getIsWrongSelect(value) {
    this.setState({
      isWrongSelect: value,
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.isSelectItemUpdate) {
      this.setState({
        isSelectItemUpdate: false,
      });
      return false;
    }
    if (nextState.isButtonClicked) {
      if (nextState.isAdded) {
        this.setState({
          isButtonClicked: false,
          isAdded: false,
        });
      }
      this.setState({
        isAdded: true,
      });
    }
    if (this.state.onEnterPress)
      this.setState({
        onEnterPress: false,
      });
    return true;
  }

  render() {
    return (
      <Grid.Row stretched>
        <Grid.Column width={3} verticalAlign="middle">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h3
              style={{
                color: 'black',
                whiteSpace: 'no-wrap',
                flex: 1,
                margin: 0,
              }}
            >
              Product
            </h3>
            <img
              style={{ flex: 0 }}
              src={ChangeIcon}
              height="30px"
              width="30px"
              onClick={this.changeModeButtonOnClickHandler}
            />
          </div>
        </Grid.Column>
        <Grid.Column width={9} verticalAlign="middle">
          {this.state.isSingleSearchMode ? (
            <ProductSuggestBox
              productList={this.props.productList}
              onSelect={this.getSelecteditem}
              voltage={this.props.voltage}
            />
          ) : (
            <ProductSuggestDropDown
              productList={this.props.productList}
              voltage={this.props.voltage}
              onSelect={this.getSelecteditem}
              isAdded={this.state.isButtonClicked}
              selectedCustomer={this.props.selectedCustomer}
              isWrongSelect={this.state.isWrongSelect}
              getIsWrongSelect={this.getIsWrongSelect}
              onEnterPress={this.state.onEnterPress}
            />
          )}
        </Grid.Column>
        <Grid.Column width={2} verticalAlign="middle">
          <Input
            value={this.state.quantity}
            onChange={this.quantityInputOnChangeHandler}
            onKeyPress={this.quantityInputOnKeyPressHandler}
            error={this.state.isWrongQuantity}
          />
        </Grid.Column>
        <Grid.Column width={2} verticalAlign="middle">
          <Button
            size="big"
            onClick={() => {
              if (this.state.quantity > 0) {
                if (
                  this.state.seletedItem != null &&
                  this.state.seletedItem.length == 1
                ) {
                  this.props.onAdd(
                    this.state.seletedItem[0],
                    this.state.quantity,
                    this.props.discount,
                    false
                  );
                  this.setState({
                    seletedItem: null,
                    isButtonClicked: true,
                    isWrongSelect: false,
                  });
                } else {
                  this.setState({
                    isWrongSelect: true,
                  });
                }
              } else {
                this.setState({
                  isWrongQuantity: true,
                });
              }
            }}
          >
            Add
          </Button>
        </Grid.Column>
      </Grid.Row>
    );
  }
}

ProductSelector.PropTypes = propTypes;
ProductSelector.defaultProps = defaultProps;

export default ProductSelector;
