import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';

const ProductSuggestBoxItem = ({ product, onClick }) => {
  return (
    <List.Item
      onClick={() => {
        onClick(product);
      }}
      tabIndex={0}
    >
      <List.Content>
        <List.Header>
          <span
            style={{
              width: '20%',
              display: 'inline-block',
              textAlign: 'center',
            }}
          >
            {product.Model ? product.Model.trim() : 'null'}
          </span>
          <span
            style={{
              width: '20%',
              display: 'inline-block',
              textAlign: 'center',
            }}
          >
            {product.Color ? product.Color.trim() : 'null'}
          </span>
          <span
            style={{
              width: '20%',
              display: 'inline-block',
              textAlign: 'center',
            }}
          >
            {product.Voltage
              ? product.Voltage.trim() != 'Free Voltage'
                ? product.Voltage
                : 'Free'
              : 'null'}
          </span>
          <span
            style={{
              width: '20%',
              display: 'inline-block',
              textAlign: 'center',
            }}
          >
            {product.HDD ? product.HDD.trim() : 'null'}
          </span>
          <span
            style={{
              width: '20%',
              display: 'inline-block',
              textAlign: 'center',
            }}
          >
            {product.SSD ? product.SSD.trim() : 'null'}
          </span>
        </List.Header>
      </List.Content>
    </List.Item>
  );
};

ProductSuggestBoxItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  product: PropTypes.shape({
    ProductCode: PropTypes.string,
    ProductID: PropTypes.number.isRequired,
    ModelT: PropTypes.string,
    Model: PropTypes.string,
    Color: PropTypes.string,
    Voltage: PropTypes.string,
    HDD: PropTypes.string,
    SSD: PropTypes.string,
    USDRetail: PropTypes.string,
    KRWRetail: PropTypes.string,
  }),
};

export default ProductSuggestBoxItem;
