import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAddress } from '../../../action/account';
import CustomerAddressChangeMenu from '../presenters/CustomerAddressChangeMenu';
import { OutsideClickCloser } from '../../General/OutsideClickCloser';

class CustomerAddressChangeMenuContainer extends Component {
  componentWillMount() {
    if (!this.props.addressList[this.props.AccountID]) {
      this.props.getAddress(this.props.AccountID);
    }
  }

  render() {
    const {
      addressList,
      AccountID,
      closeCustomerShppingAddressSelector,
      onAddressSelect,
    } = this.props;

    return (
      <React.Fragment>
        <OutsideClickCloser onClick={closeCustomerShppingAddressSelector} />
        <CustomerAddressChangeMenu
          addressList={addressList[AccountID]}
          closeCustomerShppingAddressSelector={
            closeCustomerShppingAddressSelector
          }
          onAddressSelect={onAddressSelect}
        />
      </React.Fragment>
    );
  }
}

export default connect(
  (state) => ({
    addressList: state.addressList,
  }),
  (dispatch) => ({
    getAddress: bindActionCreators(getAddress, dispatch),
  })
)(CustomerAddressChangeMenuContainer);
