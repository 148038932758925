import React, { Component } from 'react';
import ReactCardFlip from 'react-card-flip';
import TrueFalseSign from './TrueFalseSign';
import withSaveAndUndo from '../../../HOCs/withSaveAndUndo';

class AccountStatus extends Component {
  constructor(props) {
    super(props);
  }

  onChange = () => {
    this.props.onChange(!this.props.value);
  };

  render() {
    return (
      <div
        style={{
          width: '100px',
          display: 'inline-block',
          textAlign: 'center',
          verticalAlign: 'top',
        }}
      >
        <ReactCardFlip
          isFlipped={this.props.value}
          flipSpeedBackToFront={0.3}
          flipSpeedFrontToBack={0.3}
        >
          <TrueFalseSign
            key="front"
            onClick={this.onChange}
            isTrue={this.props.isActive}
            falseMessage="in active"
            trueMessage="active"
          />
          <TrueFalseSign
            key="back"
            onClick={this.onChange}
            isTrue={!this.props.isActive}
            falseMessage="in active"
            trueMessage="active"
          />
        </ReactCardFlip>
      </div>
    );
  }
}

export default withSaveAndUndo(AccountStatus);
