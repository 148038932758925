import axios from '../util/CustomAxios';

export const ADD_CONTACT_LIST = 'ADD_CONTACT_LIST';
export const INSERT_CONTACT = 'INSERT_CONTACT';
export const SET_EDIT_MODE_CONTACT = 'SET_EDIT_MODE_CONTACT';

export function addContactList(accountId, contactList) {
  return {
    type: ADD_CONTACT_LIST,
    accountId: accountId,
    contactList: contactList,
  };
}

export function setEditModeContact(contact) {
  return {
    type: SET_EDIT_MODE_CONTACT,
    contact: contact,
  };
}

export const getContactList = (accountId) => (dispatch) => {
  return axios
    .get('/Contact/' + accountId)
    .then((response) => {
      dispatch(addContactList(accountId, response.data));
    })
    .catch(function (error) {});
};

export const insertContact = (newContact) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post('/Contact', newContact)
      .then((response) => {
        const { newContactId, contacts } = response.data;
        dispatch(addContactList(newContact.account_id, contacts));
        resolve({ newContactId, contacts });
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
export const updateContact = (contact, contactId) => (dispatch) => {
  return axios
    .put('/Contact/' + contactId, contact)
    .then((response) => {
      dispatch(addContactList(response.data[0].account_id, response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const deleteContact = (contactId, account_id) => (dispatch) => {
  return axios
    .delete('/Contact/' + account_id + '/' + contactId)
    .then((response) => {
      dispatch(addContactList(response.data[0].account_id, response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
};
