import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getSalesStatisticsData,
  getSelectedSalesStatistisColumn,
  getProductStatisticsData,
  getSelectedProductStatistisColumn,
} from '../../../action/statistics';

import SalesStatisticsBar from '../Presenter/SalesStatisticsBar';
import SalesStatisticsTable from '../Presenter/SalesStatisticsTable';
import ProductStatisticsBar from '../Presenter/ProductStatisticsBar';
import ProductStatisticsTable from '../Presenter/ProductStatisticsTable';

class Statistics_BarTableContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }
  filterDataAccordingToIsTestShow(dataArray, isTestShow) {
    return dataArray.filter((data) => {
      if (isTestShow) return true;
      else if (data.Test) return false;
      else return true;
    });
  }

  componentWillMount() {
    this.props.getSalesStatisticsData(
      this.props.company,
      this.props.year,
      this.props.accountID
    );
  }
  componentDidMount() {
    this.setState({
      data: this.filterDataAccordingToIsTestShow(
        this.props.salesStatisticsData,
        this.props.isTestShow
      ),
    });
  }
  componentWillReceiveProps(nextProps) {
    console.log(nextProps.salesStatisticsData);
    if (this.props.item != nextProps.item) {
      if (nextProps.item == 'Sales Order') {
        this.props.getSalesStatisticsData(
          nextProps.company,
          nextProps.year,
          nextProps.accountID
        );
        // this.props.getSelectedSalesStatistisColumn(13, "SOIssued", "ALL")
      } else if (nextProps.item == 'Product') {
        this.props.getProductStatisticsData(
          nextProps.company,
          nextProps.year,
          nextProps.accountID
        );
        // this.props.getSelectedProductStatistisColumn(13, "ALL")
      }
    } else {
      if (nextProps.item == 'Sales Order') {
        if (this.props.salesStatisticsData != nextProps.salesStatisticsData) {
          this.setState({
            data: this.filterDataAccordingToIsTestShow(
              nextProps.salesStatisticsData,
              nextProps.isTestShow
            ),
          });
        }
        if (this.props.company != nextProps.company) {
          this.props.getSalesStatisticsData(
            nextProps.company,
            nextProps.year,
            nextProps.accountID
          );
        }
        if (this.props.year != nextProps.year) {
          this.props.getSalesStatisticsData(
            nextProps.company,
            nextProps.year,
            nextProps.accountID
          );
          this.props.getSelectedSalesStatistisColumn(13, 'SOIssued', 'ALL');
        }
        if (this.props.type != nextProps.type) {
          if (nextProps.item == 'SalesOrder') {
            if (nextProps.type == 'Compare')
              this.props.getSelectedSalesStatistisColumn(
                new Date().getMonth() + 1,
                'Shipped',
                'ALL'
              );
            else if (nextProps.type == 'Amount')
              this.props.getSelectedSalesStatistisColumn(
                new Date().getMonth() + 1,
                'Shipped',
                'ALL'
              );
            else if (nextProps.type == 'Status')
              this.props.getSelectedSalesStatistisColumn(13, 'SOIssued', 'ALL');
          }
        }
        if (this.props.isTestShow != nextProps.isTestShow) {
          this.setState({
            data: this.filterDataAccordingToIsTestShow(
              nextProps.salesStatisticsData,
              nextProps.isTestShow
            ),
          });
        }
      } else if (nextProps.item == 'Product') {
        if (
          this.props.productStatisticsData != nextProps.productStatisticsData
        ) {
          this.setState({
            data: this.filterDataAccordingToIsTestShow(
              nextProps.productStatisticsData,
              nextProps.isTestShow
            ),
          });
        }
        if (this.props.company != nextProps.company) {
          this.props.getProductStatisticsData(
            nextProps.company,
            nextProps.year,
            nextProps.accountID
          );
        }
        if (this.props.year != nextProps.year) {
          this.props.getProductStatisticsData(
            nextProps.company,
            nextProps.year,
            nextProps.accountID
          );
          this.props.getSelectedProductStatistisColumn(13, nextProps.model);
        }
        if (this.props.type != nextProps.type) {
          if (nextProps.model == 'All') {
            this.props.getSelectedProductStatistisColumn(13, nextProps.model);
          } else {
            this.props.getSelectedProductStatistisColumn(
              new Date().getMonth() + 1,
              nextProps.model
            );
          }
        }
        if (this.props.model != nextProps.model) {
          this.props.getSelectedProductStatistisColumn(13, nextProps.model);
        }
        if (this.props.isTestShow != nextProps.isTestShow) {
          this.setState({
            data: this.filterDataAccordingToIsTestShow(
              nextProps.productStatisticsData,
              nextProps.isTestShow
            ),
          });
        }
      }
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.item != nextProps.item) return false;
    return true;
  }

  render() {
    let presenter;
    if (this.props.item == 'Sales Order') {
      presenter = (
        <div>
          <SalesStatisticsBar
            data={this.state.data}
            currency={this.props.currency}
            type={this.props.type}
            getSelectedBar={this.props.getSelectedSalesStatistisColumn}
          />
          <br />
          <SalesStatisticsTable
            data={this.state.data}
            selectedBar={this.props.selectedSalesStatisticsColumn}
            type={this.props.type}
            year={this.props.year}
          />
        </div>
      );
    } else if (this.props.item == 'Product') {
      presenter = (
        <div>
          <ProductStatisticsBar
            data={this.state.data}
            dataFrom={this.props.modelData}
            currency={this.props.currency}
            type={this.props.type}
            model={this.props.model}
            getSelectedBar={this.props.getSelectedProductStatistisColumn}
          />
          <br />
          <ProductStatisticsTable
            data={this.state.data}
            selectedBar={this.props.selectedProductStatisticsColumn}
            type={this.props.type}
            year={this.props.year}
          />
        </div>
      );
    }
    return presenter;
  }
}

export default connect(
  (state) => ({
    company: state.company,
    salesStatisticsData: state.salesStatisticsData,
    selectedSalesStatisticsColumn: state.selectedSalesStatisticsColumn,
    productStatisticsData: state.productStatisticsData,
    selectedProductStatisticsColumn: state.selectedProductStatisticsColumn,
  }),
  (dispatch) => ({
    getSalesStatisticsData: bindActionCreators(
      getSalesStatisticsData,
      dispatch
    ),
    getSelectedSalesStatistisColumn: bindActionCreators(
      getSelectedSalesStatistisColumn,
      dispatch
    ),
    getProductStatisticsData: bindActionCreators(
      getProductStatisticsData,
      dispatch
    ),
    getSelectedProductStatistisColumn: bindActionCreators(
      getSelectedProductStatistisColumn,
      dispatch
    ),
  })
)(Statistics_BarTableContainer);
