import React, { PureComponent } from 'react';
import Axios from '../../../util/CustomAxios';
import { patchAccountRequest } from '../../../action/account';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AccountStatus from '../Presenter/AccountStatus';

class AccountStatusContainer extends PureComponent {
  onStatusChange = (value) => {
    value ? (value = 1) : (value = 0);
    this.props.patchAccountRequest({ Status: value }, this.props.AccountID);
  };

  render() {
    return <AccountStatus {...this.props} onChange={this.onStatusChange} />;
  }
}

export default connect(
  (state) => ({}),
  (dispatch) => ({
    patchAccountRequest: bindActionCreators(patchAccountRequest, dispatch),
  })
)(AccountStatusContainer);
