import React, { Component } from 'react';
import AccountDetail from '../../Account/AccountDetail';
import axios from '../../../util/CustomAxios';
import { getCountryList } from '../../../action/Country';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class AccountInfoContainer extends Component {
  state = {
    accountInfo: {},
  };
  async componentDidMount() {
    await this.props.getCountryList();
    const accountId = this.props.accountId;
    const res = await axios.get(`/AccountInfo/${accountId}`);

    this.setState({
      accountInfo: res.data,
    });
  }

  render() {
    return (
      <AccountDetail
        AccountID={this.props.accountId}
        {...this.state.accountInfo}
      />
    );
  }
}

export default connect(
  (state) => ({}),
  (dispatch) => ({
    getCountryList: bindActionCreators(getCountryList, dispatch),
  })
)(AccountInfoContainer);
