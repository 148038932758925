import React from 'react';
import FlagIcon from '../../../util/FlagFactory';
import axios from '../../../util/CustomAxios';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import Autocomplete from 'react-autocomplete';

function extractCountryCode(country) {
  return country
    .substring(country.indexOf('(') + 1, country.indexOf('(') + 3)
    .toLowerCase();
}

const MENU_STYLE = {
  borderRadius: '3px',
  boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
  background: 'rgba(255, 255, 255, 0.9)',
  padding: '2px 0',
  fontSize: '90%',
  position: 'absolute',
  overflow: 'auto',
  maxHeight: '300px',
  zIndex: 10,
  top: '35px',
  left: 0,
};

const SimpleDropdown = ({
  cellInfo,
  onChange = () => {},
  onSelect = () => {},
  value = '',
  list = [],
  name,
}) => {
  return (
    <Autocomplete
      wrapperStyle={{ width: '100%', position: 'relative' }}
      menuStyle={MENU_STYLE}
      getItemValue={(item) => item.Name}
      items={list}
      shouldItemRender={(item, value) =>
        item.Name.toLowerCase().indexOf(value.toLowerCase()) > -1
      }
      renderInput={(props) => (
        <input name={name} style={{ width: '100%' }} {...props} />
      )}
      renderItem={(item, highlighted) => (
        <div
          key={item.Name}
          style={{
            backgroundColor: highlighted ? '#eee' : 'transparent',
          }}
        >
          {item.Name}
        </div>
      )}
      value={value}
      onChange={(e) => {
        onChange(e);
      }}
      onSelect={(value) => onChange({ target: { name: name, value: value } })}
    />
  );
};

export default SimpleDropdown;
