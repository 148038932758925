import withGetArrayData from '../../../HOCs/withGetArrayData';
import DataDropdown from './DataDropdown';
import withReduxFormValidation from '../../../HOCs/withReduxFormValidation';

const SalesOrderType = withGetArrayData(
  DataDropdown,
  '/SalesOrderType',
  (array) =>
    array.map((item) => {
      return {
        key: item.SOTID,
        text: item.Name,
        value: item.SOTID,
      };
    }),
  {
    company: localStorage.getItem('NowCompany'),
  }
);

export default withReduxFormValidation(SalesOrderType);
