import React, { Component, PropTypes } from 'react';
import './BallLoader.css';

export class BallLoader extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="Ball-Loader-container">
        <div className="Ball-container">
          <div className="ball"></div>
          <div className="ball"></div>
          <div className="ball"></div>
          <div className="ball"></div>
          <div className="ball"></div>
          <div className="ball"></div>
          <div className="ball"></div>
        </div>
      </div>
    );
  }
}
