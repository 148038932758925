import React, { Component, PropTypes } from 'react';
import PropTypes_ from 'prop-types';
import { MoneyInput, MoneyLabel } from './customComponents';
import { Grid, Input, Button, Table } from 'semantic-ui-react';
import ProductOrderListItem from './ProductOrderListItem';

import SubTotal from './SubTotal';

const propTypes = {};

const defaultProps = {};

class ProductOrderList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.currencyExceptionHandler = this.currencyExceptionHandler.bind(this);
    this.toIntegerHelper = this.toIntegerHelper.bind(this);
    this.calculateDiscount = this.calculateDiscount.bind(this);
  }

  currencyExceptionHandler() {
    if (this.props.selectedCustomer.Currency == undefined) return 'USD';
    return this.props.selectedCustomer.Currency.trim();
  }

  toIntegerHelper = (num) => {
    let valueString = num.toString();
    let value = valueString.split('.')[1];
    return String(value).length;
  };

  calculateDiscount = (discount) => {
    var T1 = Number('1e' + this.toIntegerHelper(discount));
    var T2 = Number(
      '1e' + this.toIntegerHelper((100 * T1 - discount * T1) / T1)
    );
    var returnDiscount =
      Math.floor(((100 * T1 - discount * T1) / T1) * T2) / (100 * T2);

    return returnDiscount;
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.productOrderList == nextProps.productOrderList) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.salesOrderType !== this.props.salesOrderType) {
      // Force a re-render when salesOrderType changes
      this.forceUpdate();
    }
  }

  render() {
    const { salesOrderType, productOrderList } = this.props;

    const ListItems = productOrderList.map((productOrder, index) => (
      <ProductOrderListItem
        key={index}
        productOrder={productOrder}
        currency={this.currencyExceptionHandler()}
        onQuantityChange={this.props.setQuantity}
        onDiscountChange={this.props.setCustomDiscount}
        onProductOrderDelete={this.props.deleteOrderProduct}
        salesOrderType={salesOrderType}
      />
    ));

    return (
      <Grid.Row stretched>
        <Grid.Column width={3} verticalAlign="middle" />
        <Grid.Column width={13}>
          <Table compact celled fixed>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={4}>Items</Table.HeaderCell>
                <Table.HeaderCell width={2}>Quantity</Table.HeaderCell>
                <Table.HeaderCell width={2}>Rate</Table.HeaderCell>
                <Table.HeaderCell width={2}>Discount</Table.HeaderCell>
                <Table.HeaderCell width={2}>FOB Price</Table.HeaderCell>
                <Table.HeaderCell width={3}>Amount</Table.HeaderCell>
                <Table.HeaderCell width={1} />
              </Table.Row>
            </Table.Header>

            {ListItems}

            <SubTotal
              subQty={this.props.productOrderList.reduce((a, b) => {
                return (
                  a +
                  (this.props.salesOrderType === 8
                    ? -Math.abs(Number(b.quantity))
                    : Number(b.quantity))
                );
              }, 0)}
              subTotal={this.props.productOrderList.reduce((a, b) => {
                let amount;
                if (
                  this.props.selectedCustomer.Currency != undefined &&
                  this.props.selectedCustomer.Currency.trim() == 'KRW'
                ) {
                  amount = Number(
                    (
                      b.product.KRWRetail *
                      Math.abs(b.quantity) *
                      this.calculateDiscount(b.discount)
                    ).toFixed(0)
                  );
                } else {
                  amount = Number(
                    (
                      b.product.USDRetail *
                      Math.abs(b.quantity) *
                      this.calculateDiscount(b.discount)
                    ).toFixed(2)
                  );
                }
                // Make the amount negative if salesOrderType is 8
                return (
                  a +
                  (this.props.salesOrderType === 8 ? -Math.abs(amount) : amount)
                );
              }, 0)}
              currency={this.currencyExceptionHandler()}
              salesOrderType={this.props.salesOrderType}
            />
          </Table>
        </Grid.Column>
      </Grid.Row>
    );
  }
}

ProductOrderList.propTypes = {
  salesOrderType: PropTypes_.number,
};
ProductOrderList.defaultProps = {
  salesOrderType: null,
};

export default ProductOrderList;
