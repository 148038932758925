import axios from '../util/CustomAxios';
export const RECEIVE_PRODUCT_LIST = 'RECEIVE_PRODUCT_LIST';
export const ADD_ORDER_PRODUCT = 'ADD_ORDER_PRODUCT';
export const DELETE_ORDER_PRODUCT = 'DELETE_ORDER_PRODUCT';
export const SET_DISCOUNT = 'SET_DISCOUNT';
export const SET_CUSTOM_DISCOUNT = 'SET_CUSTOM_DISCOUNT';
export const SET_SETTING_DISCOUNT = 'SET_SETTING_DISCOUNT';
export const SET_QUANTITY = 'SET_QUANTITY';
export const SET_INITIAL_PRODUCT_ORDER_LIST = 'SET_INITIAL_PRODUCT_ORDER_LIST';

export function receiveProductList(productList) {
  return {
    type: RECEIVE_PRODUCT_LIST,
    productList: productList,
  };
}

export const getProductList = (state) => (dispatch) => {
  return axios
    .get('/Products')
    .then((response) => {
      dispatch(receiveProductList(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
};

let nextOrderProductID = 0;
export function addOrderProductWithProductModel(
  product,
  productModel,
  quantity,
  discount,
  isCustomDiscount,
  SOIID,
  serials
) {
  return {
    type: ADD_ORDER_PRODUCT,
    id: nextOrderProductID++,
    product,
    productModel,
    quantity,
    discount,
    isCustomDiscount,
    SOIID,
    serials,
  };
}

export const addOrderProduct =
  (product, quantity, discount, isCustomDiscount, SOIID, serials) =>
  (dispatch) => {
    return axios
      .get('/ProductModel', {
        params: { ModelID: product.ModelId ? product.ModelId : 21 },
      })
      .then((response) => {
        dispatch(
          addOrderProductWithProductModel(
            product,
            response.data[0],
            quantity,
            discount,
            isCustomDiscount,
            SOIID,
            serials
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

export function deleteOrderProduct(id) {
  return {
    type: DELETE_ORDER_PRODUCT,
    id,
  };
}

export function setDiscount(discount) {
  return {
    type: SET_DISCOUNT,
    discount,
  };
}

export function setCustomDiscount(id, discount) {
  return {
    type: SET_CUSTOM_DISCOUNT,
    id,
    discount,
  };
}

export function setSettingDiscount(id, discount) {
  return {
    type: SET_SETTING_DISCOUNT,
    id,
    discount,
  };
}

export function setQuantity(id, quantity) {
  return {
    type: SET_QUANTITY,
    id,
    quantity,
  };
}

export function setInitialProductOrderList() {
  return {
    type: SET_INITIAL_PRODUCT_ORDER_LIST,
  };
}

export const getSalesOrderItems = (SOID) => (dispatch) => {
  return axios
    .get(`/SalesOrder/edit/${SOID}/Items`)
    .then((response) => {
      const items = response.data;
      items.forEach((item) => {
        axios
          .get(`/Product/${item.ProductID}`)
          .then((response) => {
            dispatch(
              addOrderProduct(
                response.data[0],
                item.Quantity,
                item.Discount,
                item.IsCustomDiscount == null ? false : item.IsCustomDiscount,
                item.SOIID
              )
            );
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getInvoiceItems = (SOID) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`/Invoice/new/${SOID}/items`);
      const items = response.data;
      console.log(items);
      for (let item of items) {
        const response = await axios.get(`/Product/${item.ProductID}`);
        console.log(item);
        await dispatch(
          await addOrderProduct(
            response.data[0],
            item.Quantity,
            item.Discount,
            item.IsCustomDiscount == null ? false : item.IsCustomDiscount,
            item.SOIID,
            item.serials
          )
        );
      }
      resolve();
    } catch (error) {
      reject(error);
    }
  });
  // return axios.get(`/Invoice/new/${SOID}/items`)
  //   .then(response => {
  //     const items = response.data
  //     console.log(items)
  //     items.map(async item => {
  //       const response = await axios.get(`/Product/${item.ProductID}`)
  //       console.log(item)
  //       await dispatch(await addOrderProduct(response.data[0], item.Quantity, item.Discount, item.IsCustomDiscount == null ? false : item.IsCustomDiscount, item.SOIID, item.serials))
  //     });
  //   })
  //   .catch(function (error) {
  //     console.log(error);
  //   })
};

export const getInvoiceEditItems = (invoice_id) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`/Invoice/edit/${invoice_id}/items`);
      const items = response.data;
      console.log(items);
      for (let item of items) {
        const response = await axios.get(`/Product/${item.ProductID}`);
        console.log(item);
        await dispatch(
          await addOrderProduct(
            response.data[0],
            item.Quantity,
            item.Discount,
            item.IsCustomDiscount == null ? false : item.IsCustomDiscount,
            item.SOIID,
            item.serials
          )
        );
      }
      resolve();
    } catch (error) {
      reject(error);
    }
  });

  // return axios.get(`/Invoice/edit/${invoice_id}/items`)
  //   .then(response => {
  //     const items = response.data
  //     items.forEach(item => {
  //       axios.get(`/Product/${item.ProductID}`)
  //         .then(response => {
  //           dispatch(addOrderProduct(response.data[0], item.Quantity, item.Discount, item.IsCustomDiscount == null ? false : item.IsCustomDiscount, item.SOIID, item.serials))
  //         })
  //         .catch(function (error) {
  //           console.log(error)
  //         })
  //     });
  //   })
  //   .catch(function (error) {
  //     console.log(error);
  //   })
};

export const getInvoiceAddableItems = (invoice_id) => (dispatch) => {
  return axios
    .get(`/Invoice/addable/${invoice_id}/items`)
    .then((response) => {
      const items = response.data;
      items.forEach((item) => {
        axios
          .get(`/Product/${item.ProductID}`)
          .then((response) => {
            dispatch(
              addOrderProduct(
                response.data[0],
                item.Quantity,
                item.Discount,
                item.IsCustomDiscount == null ? false : item.IsCustomDiscount,
                item.SOIID,
                item.serials
              )
            );
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    })
    .catch(function (error) {
      console.log(error);
    });
};
