import React, { Component } from 'react';

import {
  Grid,
  Container,
  Header,
  Button,
  Dropdown,
  Checkbox,
  Label,
} from 'semantic-ui-react';
import axios from '../../util/CustomAxios';

import Statistics_BarTableContainer from './Container/Statistics_BarTableContainer';

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      yearData: null,
      selectedYear: null,
      itemData: null,
      selectedItem: null,
      typeData: null,
      selectedType: null,
      modelData: null,
      selectedModel: null,
      currencyData: null,
      selectedCurrency: null,
    };
    this.makeProductModelData = this.makeProductModelData.bind(this);
    this.makeDropDownOptionsFromList =
      this.makeDropDownOptionsFromList.bind(this);
    this.yearDropDownOnChangeHandler =
      this.yearDropDownOnChangeHandler.bind(this);
    this.itemDropDownOnChangeHandler =
      this.itemDropDownOnChangeHandler.bind(this);
    this.typeDropDownOnChangeHandler =
      this.typeDropDownOnChangeHandler.bind(this);
    this.modelDropDownOnChangeHandler =
      this.modelDropDownOnChangeHandler.bind(this);
    this.currencyDropDownOnChangeHandler =
      this.currencyDropDownOnChangeHandler.bind(this);
  }

  makeYearData() {
    let startYear = 2016;
    let nowYear = new Date().getFullYear();
    let YearData = [];
    for (let index = startYear; index <= nowYear; index++) {
      YearData.push({
        text: `${index}`,
        value: index,
      });
    }
    return YearData;
  }

  makeProductModelData(year) {
    axios
      .get('/ProductModelForStatistics', {
        params: {
          Company: localStorage.getItem('Company'),
          Year: year,
          AccountID:
            this.props.AccountID == undefined ? null : this.props.AccountID,
        },
      })
      .then((response) => {
        let data = response.data
          .map((model) => {
            return {
              text: model.Model.trim(),
              value: model.Model.trim(),
            };
          })
          .sort(function (a, b) {
            return a.text.localeCompare(b.text);
          });
        data = [{ text: 'ALL', value: 'ALL' }, ...data];
        this.setState({
          modelData: data,
          selectedModel: 'ALL',
        });
        console.log(data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  makeDropDownOptionsFromList(List) {
    return List.map((text) => {
      return {
        text: text.trim(),
        value: text.trim(),
      };
    });
  }

  yearDropDownOnChangeHandler(event, data) {
    this.setState({
      selectedYear: data.value,
    });
    this.makeProductModelData(data.value);
  }
  itemDropDownOnChangeHandler(event, data) {
    let changedTypeData;
    let changedSelectedType;
    if (data.value == 'Sales Order') {
      changedTypeData = ['Compare', 'Amount', 'Status'];
      changedSelectedType = 'Compare';
    } else if (data.value == 'Product') {
      changedTypeData = ['Quantity', 'Amount'];
      changedSelectedType = 'Quantity';
    }
    this.setState({
      selectedItem: data.value,
      typeData: this.makeDropDownOptionsFromList(changedTypeData),
      selectedType: changedSelectedType,
    });
  }
  typeDropDownOnChangeHandler(event, data) {
    this.setState({
      selectedType: data.value,
    });
  }
  modelDropDownOnChangeHandler(event, data) {
    this.setState({
      selectedModel: data.value,
    });
  }
  currencyDropDownOnChangeHandler(event, data) {
    this.setState({
      selectedCurrency: data.value,
    });
  }
  componentWillMount() {
    this.setState({
      yearData: this.makeYearData(),
      selectedYear: new Date().getFullYear(),
      itemData: this.makeDropDownOptionsFromList(['Sales Order', 'Product']),
      selectedItem: 'Sales Order',
      typeData: this.makeDropDownOptionsFromList([
        'Compare',
        'Amount',
        'Status',
      ]),
      selectedType: !!this.props.AccountID ? 'Amount' : 'Compare',
      modelData: null,
      selectedModel: 'ALL',
      currencyData: this.makeDropDownOptionsFromList(['USD', 'KRW']),
      selectedCurrency:
        localStorage.getItem('Company') == 'Aurender Inc.' ? 'KRW' : 'USD',
    });
    this.makeProductModelData(new Date().getFullYear());
  }
  render() {
    let seletorWith;
    let selector;
    if (this.state.selectedItem == 'Sales Order') {
      seletorWith = 4;
      selector = (
        <Grid.Column width={seletorWith}>
          <h4 style={{ color: 'black', whiteSpace: 'no-wrap' }}>Type</h4>
          <Dropdown
            placeholder="Type"
            fluid
            search
            selection
            options={this.state.typeData}
            value={this.state.selectedType}
            onChange={this.typeDropDownOnChangeHandler}
          />
        </Grid.Column>
      );
    } else if (this.state.selectedItem == 'Product') {
      seletorWith = 3;
      selector = (
        <React.Fragment>
          <Grid.Column width={seletorWith}>
            <h4 style={{ color: 'black', whiteSpace: 'no-wrap' }}>Model</h4>
            <Dropdown
              placeholder="Model"
              fluid
              search
              selection
              options={this.state.modelData}
              value={this.state.selectedModel}
              onChange={this.modelDropDownOnChangeHandler}
            />
          </Grid.Column>
          <Grid.Column width={seletorWith}>
            <h4 style={{ color: 'black', whiteSpace: 'no-wrap' }}>Type</h4>
            <Dropdown
              placeholder="Type"
              fluid
              search
              selection
              options={this.state.typeData}
              value={this.state.selectedType}
              onChange={this.typeDropDownOnChangeHandler}
            />
          </Grid.Column>
        </React.Fragment>
      );
    }
    return (
      <Grid columns="equal" stackable>
        <Grid.Row stretched>
          <Grid.Column width={seletorWith}>
            <h4 style={{ color: 'black', whiteSpace: 'no-wrap' }}>Year</h4>
            <Dropdown
              placeholder="Year"
              fluid
              search
              selection
              options={this.state.yearData}
              value={this.state.selectedYear}
              onChange={this.yearDropDownOnChangeHandler}
            />
          </Grid.Column>
          <Grid.Column width={seletorWith}>
            <h4 style={{ color: 'black', whiteSpace: 'no-wrap' }}>Item</h4>
            <Dropdown
              placeholder="Item"
              fluid
              search
              selection
              options={this.state.itemData}
              value={this.state.selectedItem}
              onChange={this.itemDropDownOnChangeHandler}
            />
          </Grid.Column>
          {selector}
          <Grid.Column width={seletorWith}>
            <h4 style={{ color: 'black', whiteSpace: 'no-wrap' }}>Currency</h4>
            <Dropdown
              placeholder="Currency"
              fluid
              search
              selection
              options={this.state.currencyData}
              value={this.state.selectedCurrency}
              onChange={this.currencyDropDownOnChangeHandler}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row stretched>
          <Grid.Column width={16}>
            <Statistics_BarTableContainer
              year={this.state.selectedYear}
              item={this.state.selectedItem}
              type={this.state.selectedType}
              model={this.state.selectedModel}
              currency={this.state.selectedCurrency}
              modelData={this.state.modelData}
              isTestShow={this.props.isTestShow}
              accountID={this.props.AccountID}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default Main;
