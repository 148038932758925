export default {
  AurenderInc: {
    byCountry: [
      { Country: 'Korea (KR)', Discount: 50 },
      { Country: 'Canada (CA)', Discount: 40 },
    ],
    byCompany: [{ Company: 65, Discount: 60 }],
  },
  AurenderAmericaInc: {
    Discount: 40,
  },
};
