import React, { Component } from 'react';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import 'react-contexify/ReactContexify.css';
import TrashIcon from 'react-icons/lib/go/trashcan';
import ScissorIcon from 'react-icons/lib/fa/hand-scissors-o';

const IconStyle = {
  verticalAlign: 'text-top',
  marginRight: '4px',
};

const MenuItemStyle = {
  padding: ' 8px 15px',
  cursor: 'point',
};

const MenuContainerStyle = {
  background: 'rgba(40,40,40,.98)',
  color: 'white',
  boxShadow: '0 2px 15px rgba(0,0,0,.4), 0 0 0 1px #222',
  zIndex: 10,
};

// create your menu first
export const AddressContextMenu = (props) => {
  return (
    <ContextMenu hideOnLeave={true} id={props.id} style={MenuContainerStyle}>
      <MenuItem onClick={props.onDeleteButtonClick}>
        <div className="MenuItem" style={MenuItemStyle}>
          <TrashIcon style={IconStyle} />
          Remove
        </div>
      </MenuItem>
      <MenuItem onClick={props.onEditButtonClick}>
        <div className="MenuItem" style={MenuItemStyle}>
          <ScissorIcon style={IconStyle} />
          Modify
        </div>
      </MenuItem>
      <MenuItem divider />
    </ContextMenu>
  );
};
