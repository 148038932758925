import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  openCustomerShppingAddressSelector,
  closeCustomerShppingAddressSelector,
  openCustomerBillingAddressSelector,
  closeCustomerBillingAddressSelector,
  setSelectedBillingAddress,
  setSelectedShippingAddress,
} from '../../../action/customer';
import CustomerDetailWrapper from '../presenters/CustomerDetailWrapper';
import { patchAddressRequest } from '../../../action/account';

const BillingCustomerDetailContainer = ({
  selectedCustomer,
  customerAddress,
  openCustomerShppingAddressSelector,
  closeCustomerShppingAddressSelector,
  isCustomerShppingAddressSelectorOpen,
  isCustomerBillingAddressSelectorOpen,
  openCustomerBillingAddressSelector,
  closeCustomerBillingAddressSelector,
  selectedBillingAddress,
  selectedShippingAddress,
  setSelectedBillingAddress,
  setSelectedShippingAddress,
  patchAddressRequest,
  fields,
}) => {
  return (
    <div>
      <CustomerDetailWrapper
        patchAddressRequest={patchAddressRequest}
        selectedCustomer={selectedCustomer}
        customerAddress={customerAddress}
        closeAddressSelector={closeCustomerBillingAddressSelector}
        isCustomerAddressSelectorOpen={isCustomerBillingAddressSelectorOpen}
        openCustomerAddressSelector={openCustomerBillingAddressSelector}
        header="Billing Address"
        selectedAddress={selectedBillingAddress}
        setSelectedAddress={(address) => {
          fields.bill_to.input.onChange(address.id);
          setSelectedBillingAddress(address);
        }}
        fields={fields}
        type="billing"
      />
    </div>
  );
};

export default connect(
  (state) => ({
    selectedCustomer: state.selectedCustomer.billingCustomer,
    customerAddress: state.customerAddress.billingAddress,
    isCustomerBillingAddressSelectorOpen:
      state.isCustomerBillingAddressSelectorOpen,
    selectedBillingAddress: state.selectedAddress.billingAddress,
  }),
  (dispatch) => ({
    openCustomerBillingAddressSelector: bindActionCreators(
      openCustomerBillingAddressSelector,
      dispatch
    ),
    closeCustomerBillingAddressSelector: bindActionCreators(
      closeCustomerBillingAddressSelector,
      dispatch
    ),
    setSelectedBillingAddress: bindActionCreators(
      setSelectedBillingAddress,
      dispatch
    ),
    patchAddressRequest: bindActionCreators(patchAddressRequest, dispatch),
  })
)(BillingCustomerDetailContainer);
