import ReactTable from 'react-table';

import NumberFormat from 'react-number-format';
import React, { Component } from 'react';

const StatusData = [
  {
    text: 'Packed',
    value: 1,
  },
  {
    text: 'Picked Up',
    value: 2,
  },
  {
    text: 'Shipped',
    value: 3,
  },
  {
    text: 'SOIssued',
    value: 4,
  },
  {
    text: 'Test Completed',
    value: 5,
  },
];

class SalesStatisticsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isFirst: true,
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.data != nextProps.data) {
      this.setState({
        data: this.filterDataAccordingToSelectedBar(
          nextProps.data,
          nextProps.selectedBar,
          nextProps.type,
          nextProps.year
        ),
      });
    }
    if (
      this.props.selectedBar != nextProps.selectedBar &&
      nextProps.selectedBar.month != undefined &&
      nextProps.selectedBar.status != undefined
    ) {
      this.setState({
        data: this.filterDataAccordingToSelectedBar(
          nextProps.data,
          nextProps.selectedBar,
          nextProps.type,
          nextProps.year
        ),
        isFirst: false,
      });
    }
  }

  filterDataAccordingToSelectedBar(dataArray, selectedBar, type, year) {
    let status = null;
    StatusData.forEach((data) => {
      if (data.text == selectedBar.status) status = data.value;
    });
    let filterdData = [];
    if (type == 'Compare' || type == 'Amount') {
      filterdData =
        selectedBar.month == 13
          ? dataArray.filter((data) => {
              if (status == data.Status) return true;
              return false;
            })
          : dataArray.filter((data) => {
              if (
                selectedBar.month ==
                  new Date(data.DateofShipment).getMonth() + 1 &&
                status == data.Status
              ) {
                if (selectedBar.currency == 'ALL') return true;
                else {
                  if (selectedBar.currency == data.Currency.trim()) return true;
                  return false;
                }
              }
              return false;
            });
    } else if ((type = 'Status')) {
      let yearFilter = dataArray.filter((data) => {
        if (year == new Date(data.SOIssueDate).getFullYear()) return true;
        return false;
      });
      filterdData =
        selectedBar.month == 13
          ? yearFilter.filter((data) => {
              if (status == data.Status) return true;
              return false;
            })
          : yearFilter.filter((data) => {
              if (
                selectedBar.month ==
                  new Date(data.SOIssueDate).getMonth() + 1 &&
                status == data.Status
              ) {
                if (selectedBar.currency == 'ALL') return true;
                else {
                  if (selectedBar.currency == data.Currency.trim()) return true;
                  return false;
                }
              }
              return false;
            });
    }

    return filterdData;
  }

  makeChartTableData(dataArray) {
    return dataArray.map((data) => {
      return {
        soNumber: data.SONumber.trim(),
        status: StatusData[data.Status - 1].text,
        customer: data.Name.trim(),
        soIssueDate: new Date(data.SOIssueDate).toISOString().substring(0, 10),
        dateOfShipment:
          data.DateofShipment != null
            ? new Date(data.DateofShipment).toISOString().substring(0, 10)
            : null,
        currency: data.Currency.trim(),
        amount: (
          <span style={{ textAlign: 'right', display: 'block' }}>
            <NumberFormat
              value={data.Amount}
              displayType={'text'}
              thousandSeparator={true}
              prefix={
                data.Currency == null
                  ? '＄'
                  : data.Currency.trim() == 'USD'
                    ? '＄'
                    : data.Currency.trim() == 'KRW'
                      ? '￦'
                      : '＄'
              }
            />
          </span>
        ),
      };
    });
  }

  render() {
    const columns = [
      {
        Header: 'SO Number',
        accessor: 'soNumber',
        maxWidth: 130,
        sortMethod: (a, b) => {
          if (a.length === b.length) {
            return a > b ? 1 : -1;
          }
          return a.length > b.length ? 1 : -1;
        },
      },
      {
        Header: 'SO Issue Date',
        accessor: 'soIssueDate',
        maxWidth: 130,
      },
      {
        Header: 'Date of Shipment',
        accessor: 'dateOfShipment',
        maxWidth: 130,
      },
      {
        Header: 'Status',
        accessor: 'status',
        maxWidth: 150,
      },
      {
        Header: 'Customer',
        accessor: 'customer',
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        maxWidth: 120,
        sortMethod: (a, b) => {
          if (
            a.props.children.props.value.length ===
            b.props.children.props.value.length
          ) {
            return a.props.children.props.value > b.props.children.props.value
              ? 1
              : -1;
          }
          return a.props.children.props.value.length >
            b.props.children.props.value.length
            ? 1
            : -1;
        },
      },
    ];

    const sortOptions = [{ id: 'soIssueDate', desc: true }];
    return (
      <ReactTable
        sorted={sortOptions}
        data={this.makeChartTableData(this.state.data)}
        columns={columns}
        style={{ height: '600px' }}
        defaultPageSize={20}
      />
    );
  }
}

export default SalesStatisticsTable;
