import React, { Component, PropTypes } from 'react';
import PleaseLoginLoginImage from '../../images/IdontKnowWhoYouAre.png';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button/Button';
import Container from 'semantic-ui-react/dist/commonjs/elements/Container/Container';
import axios from '../../util/CustomAxios';
import config from '../../config';
import * as Msal from 'msal';
import { Redirect } from 'react-router-dom';

export class Unauthorized extends Component {
  constructor(props) {
    super(props);
    this.clientApplication = new Msal.UserAgentApplication(
      config.graphConfig.clientID,
      null,
      function (errorDesc, token, error, tokenType) {
        // Called after loginRedirect or acquireTokenPopup
      }
    );

    this.requestLogin = this.requestLogin.bind(this);
  }

  serverLogin(token) {
    axios.post(config.host + '/api/Login', { token: token }).then((res) => {
      localStorage.setItem('APIToken', res.data.token);
      axios.defaults.headers.common['x-access-token'] = res.data.token;
      window.location.reload();
    });
  }

  requestLogin() {
    const clientApplication = this.clientApplication;

    clientApplication.loginPopup(config.graphConfig.graphScopes).then(
      (idToken) => {
        clientApplication
          .acquireTokenSilent(config.graphConfig.graphScopes)
          .then(
            (accessToken) => {
              localStorage.token = accessToken;
              this.serverLogin(accessToken);
            },
            function (error) {
              clientApplication
                .acquireTokenPopup(config.graphConfig.graphScopes)
                .then(
                  function (accessToken) {
                    localStorage.token = accessToken;
                    this.serverLogin(accessToken);
                  },
                  function (error) {
                    window.alert('Error acquiring the popup:\n' + error);
                  }
                );
            }
          );
      },
      function (error) {
        window.alert('Error during login:\n' + error);
      }
    );
  }

  render() {
    return (
      <div>
        <Redirect to={`/`} />
      </div>
    );
  }
}
