import React, { Component, PropTypes } from 'react';
import PropTypes_ from 'prop-types';
import {
  Grid,
  Header,
  Segment,
  Input,
  Dropdown,
  Label,
  List,
} from 'semantic-ui-react';
import NumberFormat from 'react-number-format';

import '../../../styles/SalesOrder.css';

const propTypes = {
  text: PropTypes_.text,
  type: PropTypes_.text,
};

const defaultProps = {
  text: '',
  type: '',
};

export class MoneyLabel extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <h3>{this.props.text}</h3>
      </div>
    );
  }
}

export class MoneyInput extends Component {
  constructor(props) {
    super(props);
    this.numberFormatOnChangeHandler =
      this.numberFormatOnChangeHandler.bind(this);
  }

  numberFormatOnChangeHandler(event, data) {
    // 화폐문자 제거
    if (event.target.value.slice(0, 1) == '-') {
      let value = event.target.value.slice(2).replace(/,/gi, '');
      this.props.input.onChange(value * -1);
    } else {
      let value = event.target.value.slice(1).replace(/,/gi, '');
      this.props.input.onChange(value);
    }
  }
  componentWillReceiveProps(nextprops) {
    if (this.props.selectedCustomer != nextprops.selectedCustomer) {
      this.props.input.onChange(0);
    }
  }

  render() {
    return (
      <div className="ui input">
        <NumberFormat
          style={{ textAlign: 'right' }}
          value={!!this.props.input ? this.props.input.value : 0}
          onChange={this.numberFormatOnChangeHandler}
          thousandSeparator={true}
          prefix={this.props.prefix}
        />
      </div>
    );
  }
}

export class LabelInput extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="ui labeled input" style={{ width: '100%' }}>
        <div className="ui label">{this.props.text}</div>
        <input
          {...this.props.input}
          {...this.props}
          className="label-input"
          type="number"
          placeholder="Number"
        />
      </div>
    );
  }
}

export class ColumnLabel extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <h3 style={{ color: 'black', whiteSpace: 'no-wrap' }} {...this.props}>
          {this.props.text}
        </h3>
      </div>
    );
  }
}

export class LabelwithDropbox extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event, data) {
    this.props.input.onChange(data.value);
  }

  render() {
    const { labelName, data, input } = this.props;
    return (
      <React.Fragment>
        <Grid.Column width={3} verticalAlign="middle">
          <ColumnLabel text={labelName} />
        </Grid.Column>
        <Grid.Column width={5} verticalAlign="middle">
          <Dropdown
            placeholder="Type"
            fluid
            search
            selection
            options={data}
            onChange={this.onChange}
          />
        </Grid.Column>
      </React.Fragment>
    );
  }
}

LabelInput.PropTypes = propTypes;
LabelInput.defaultProps = defaultProps;
