import React, { Component } from 'react';
import withGetArrayData from '../../../HOCs/withGetArrayData';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import ClickableSpan from '../../General/ClickableSpan';

class AccountTypeDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: this.props.value,
      prevSelectedOption: this.props.value,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      selectedOption: nextProps.value,
      prevSelectedOption: nextProps.value,
    });
  }

  handleChange = (event, data) => {
    this.setState({
      selectedOption: data,
      prevSelectedOption: this.state.selectedOption,
    });
  };

  undoChange = () => {
    this.setState({
      selectedOption: this.props.value,
      prevSelectedOption: this.props.value,
    });
  };

  onChangeSuccess = () => {
    this.setState({
      isChanged: true,
    });
  };

  render() {
    const { selectedOption } = this.state;
    const value = selectedOption && selectedOption.value;
    return (
      <React.Fragment>
        <Dropdown
          style={{ display: 'inline-block', width: '200px' }}
          fluid
          search
          selection
          name="accountType"
          value={value}
          onChange={this.handleChange}
          options={this.props.data}
          clearable={false}
        />
        <span>
          {this.state.selectedOption !== this.state.prevSelectedOption ? (
            <span>
              <ClickableSpan
                text="Click here to save change"
                onClick={() => {
                  this.props.submitTypeChange(
                    this.state.selectedOption.value,
                    this.onChangeSuccess
                  );
                }}
              />
              <ClickableSpan text="Undo" onClick={this.undoChange} />
            </span>
          ) : null}
        </span>
      </React.Fragment>
    );
  }
}

export default withGetArrayData(
  AccountTypeDropdown,
  '/getAccountTypeList',
  (array) =>
    array.map((item) => {
      return {
        value: item.TypeID,
        key: item.TypeID,
        text: item.Type,
      };
    })
);
