import React, { Component } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import CountryWithFlag from './CountryWithFlag';
import SimpleDropdown from './SimpleDropdown';
import CountrySimpleDropdown from './CountrySimpleDropdown';
import SimpleMulipleDropdown from './SimpleMulipleDropdown';
import '../Account.css';
import ReactTableWithContextMenu from './ReactTableWithContextMenu';
import AddressContextMenuContainer from '../Container/AddressContextMenuContainer';
import { matchSorter } from 'match-sorter';
import AddressInputModal from './AddressInputModal';
import { isStateRequiredCountry } from '../../../util/Address';

const ContextMenuTable = ReactTableWithContextMenu(ReactTable);

const billingShippingOptions = [
  { label: 'billing', value: 'billing' },
  { label: 'shipping', value: 'shipping' },
];

export default class AddressList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newAddress: {
        country: '',
        state: '',
        city: '',
        address: '',
        postal_code: '',
        type: '',
        account_id: this.props.AccountID,
        selectedCountry: {},
        attention_input: '',
      },
      validationError: {
        country: false,
        address: false,
      },
      addresses: [],
      showPopup: false,
    };

    // Bind methods
    this.onNewAddressChange = this.onNewAddressChange.bind(this);
    this.onNewTypeChange = this.onNewTypeChange.bind(this);
    this.onNewCountryChange = this.onNewCountryChange.bind(this);
    this.onActiveChange = this.onActiveChange.bind(this);
    this.onAddressChange = this.onAddressChange.bind(this);
    this.onSubmitChangedAddress = this.onSubmitChangedAddress.bind(this);
    this.onTypeChange = this.onTypeChange.bind(this);
    this.onNewAddressSubmit = this.onNewAddressSubmit.bind(this);
    this.togglePopup = this.togglePopup.bind(this);
  }

  componentDidMount() {
    this.setState({ addresses: this.props.AddressList });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ contacts: nextProps.addressList });
    if (this.props.AccountID != nextProps.AccountID) {
      this.setState({
        newAddress: {
          country: '',
          state: '',
          city: '',
          address: '',
          postal_code: '',
          type: '',
          account_id: nextProps.AccountID,
          selectedCountry: {},
          attention_input: '',
        },
      });
    }
  }

  onSubmitChangedAddress(row) {
    this.props.updateAddressRequest(
      this.state.addresses[row.index],
      this.state.addresses[row.index].id
    );
  }

  onAddressChange(row, e) {
    const data = [...this.state.addresses];
    data[row.index][row.column.id] = e.target.value;
    this.setState({ addresses: data });
  }

  onTypeChange(row, type) {
    const data = [...this.state.addresses];
    data[row.index].type = type;
    this.setState({ addresses: data });
  }

  onActiveChange(address) {
    this.props.updateAddressRequest(
      { ...address, ...{ active: !address.active } },
      address.id
    );
  }

  onInlineTypeChange(address, type) {
    this.props.updateAddressRequest(
      { ...address, ...{ type: type } },
      address.id
    );
  }

  onNewAddressChange(e) {
    if (e.target.name === 'address') {
      if (e.target.value !== '') {
        this.setState({
          validationError: {
            ...this.state.validationError,
            address: false,
          },
        });
      }
    }
    const data = {};
    data[e.target.name] = e.target.value;
    this.setState({ newAddress: { ...this.state.newAddress, ...data } });
  }

  onNewCountryChange(e) {
    this.setState({
      newAddress: {
        ...this.state.newAddress,
        ...{
          selectedCountry: e.target.value,
          country: e.target.value.CountryID,
        },
      },
      validationError: {
        ...this.state.validationError,
        country: false,
      },
    });
  }

  onNewTypeChange(types) {
    this.setState({
      newAddress: {
        ...this.state.newAddress,
        ...{ type: types },
      },
    });
  }

  onNewAddressSubmit() {
    // validation check
    // if country is not selected or country is not in countryList
    if (
      !this.state.newAddress.country ||
      !this.props.countryList.some(
        (country) => country.CountryID === this.state.newAddress.country
      )
    ) {
      this.setState({
        validationError: {
          ...this.state.validationError,
          country: true,
        },
      });
      alert('Please select a valid country.');
      return;
    }
    // if country is US and state is not selected
    if (
      isStateRequiredCountry(this.state.newAddress.country.CountryCode) &&
      !this.state.newAddress.state
    ) {
      this.setState({
        validationError: {
          ...this.state.validationError,
          state: true,
        },
      });
      alert('Please select a valid state.');
      return;
    }

    this.props.insertAddress(this.state.newAddress);
    this.setState({
      newAddress: {
        country: '',
        state: '',
        city: '',
        address: '',
        postal_code: '',
        type: '',
        account_id: this.props.AccountID,
        selectedCountry: {},
      },
      showPopup: false,
    });
  }

  togglePopup() {
    this.setState((prevState) => ({ showPopup: !prevState.showPopup }));
  }

  render() {
    const {
      country,
      address,
      city,
      state,
      type,
      postal_code,
      selectedCountry,
    } = this.state.newAddress;

    const { addresses } = this.state;

    const { AddressList, countryList, stateList } = this.props;

    const normalCellGenerator = (row) =>
      row.original.isEdit ? (
        <input
          style={{ width: '100%' }}
          value={addresses[row.index][row.column.id]}
          onChange={(e) => this.onAddressChange(row, e)}
        />
      ) : (
        <div style={{ overflow: 'auto' }}>
          <span>{row.value}</span>
        </div>
      );

    return (
      <React.Fragment>
        <button
          onClick={this.togglePopup}
          style={{
            marginBottom: 20,
            width: 'fit-content',
          }}
        >
          Add New Address ✚
        </button>
        <ContextMenuTable
          style={{
            overflow: 'scroll',
          }}
          data={AddressList ? [...AddressList] : []}
          filterable
          columns={[
            {
              Header: 'Active',
              Cell: (row) =>
                row.original.isEdit ? (
                  <input
                    type="button"
                    value="OK"
                    onClick={() => this.onSubmitChangedAddress(row)}
                  />
                ) : (
                  <input
                    type="checkbox"
                    checked={row.original.active}
                    onChange={() => this.onActiveChange(row.original)}
                  />
                ),
              width: 50,
            },
            {
              Header: 'Country',
              accessor: 'countryName',
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ['countryName'] }),
              filterAll: true,
              Cell: (row) =>
                row.original.isEdit ? (
                  <CountrySimpleDropdown
                    list={this.props.countryList}
                    value={
                      addresses[row.index].selectedCountry
                        ? addresses[row.index].selectedCountry.Name
                        : addresses[row.index].countryName
                    }
                    name="country"
                    onChange={(e) => this.onCountryChange(row, e)}
                  />
                ) : (
                  <CountryWithFlag country={row.value} />
                ),
              width: 200,
            },
            {
              Header: 'State',
              accessor: 'state',
              width: 180,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ['state'] }),
              filterAll: true,
              Cell: (row) =>
                row.original.isEdit ? (
                  <SimpleDropdown
                    list={this.props.stateList.map((state) => ({
                      Name: `${state.name.trim()} (${state.code})`,
                    }))}
                    value={addresses[row.index][row.column.id]}
                    name="state"
                    onChange={(e) => this.onAddressChange(row, e)}
                  />
                ) : (
                  <span>{row.value}</span>
                ),
            },
            {
              Header: 'City',
              accessor: 'city',
              filterAll: true,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ['city'] }),
              Cell: normalCellGenerator,
              width: 200,
            },
            {
              Header: 'Address',
              accessor: 'address',
              filterAll: true,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ['address'] }),
              Cell: normalCellGenerator,
              width: 400,
            },
            {
              Header: 'Attention Input',
              accessor: 'attention_input',
              filterAll: true,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ['attention_input'] }),
              Cell: normalCellGenerator,
              width: 300,
            },
            {
              Header: 'Postal Code',
              accessor: 'postal_code',
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ['postal_code'] }),
              filterAll: true,
              Cell: normalCellGenerator,
              width: 100,
            },
            {
              Header: 'Type',
              Cell: (row) =>
                row.original.isEdit ? (
                  <SimpleMulipleDropdown
                    simpleValue={true}
                    clearable={false}
                    style={{ height: '15px' }}
                    multi={true}
                    name="type"
                    value={addresses[row.index].type}
                    onChange={(type) => this.onTypeChange(row, type)}
                    options={billingShippingOptions}
                  />
                ) : (
                  <SimpleMulipleDropdown
                    simpleValue={true}
                    clearable={false}
                    style={{ height: '15px' }}
                    multi={true}
                    name="type"
                    value={
                      (row.original.default_billing_address == row.original.id
                        ? 'billing'
                        : '') +
                      (row.original.default_shipping_address == row.original.id
                        ? row.original.default_billing_address ==
                          row.original.id
                          ? ',shipping'
                          : 'shipping'
                        : '')
                    }
                    onChange={(type) =>
                      this.onInlineTypeChange(row.original, type)
                    }
                    options={billingShippingOptions}
                  />
                ),
              width: 200,
            },
          ]}
          contextMenuComponent={AddressContextMenuContainer}
          contextMenuName="Address"
          defaultPageSize={10}
          showPagination={true}
          className="-striped -highlight"
          getTableProps={(state, rowInfo, column) => ({
            style: { overflow: 'initial' },
          })}
          getTdProps={(state, rowInfo, column) => ({
            style: {
              overflow: 'initial',
              opacity: !!rowInfo?.original.active ? 1 : 0.5,
            },
          })}
          getTbodyProps={(state, rowInfo, column) => ({
            style: { overflow: 'initial' },
          })}
          getTheadFilterThProps={(state, rowInfo, column) => ({
            style: { overflow: 'initial' },
          })}
        />

        {this.state.showPopup && (
          <AddressInputModal
            newAddress={this.state.newAddress}
            validationError={this.state.validationError}
            countryList={countryList}
            stateList={stateList}
            onNewAddressChange={this.onNewAddressChange}
            onNewCountryChange={this.onNewCountryChange}
            onNewTypeChange={this.onNewTypeChange}
            onNewAddressSubmit={this.onNewAddressSubmit}
            onCancel={this.togglePopup}
          />
        )}
      </React.Fragment>
    );
  }
}
