import * as customer from './customer';
import * as product from './product';
import * as salesOrder from './salesOrder';
import * as user from './user';
import * as statistics from './statistics';

export default {
  customer,
  product,
  salesOrder,
  user,
  statistics,
};
