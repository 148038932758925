import { ADD_CONTACT_LIST, SET_EDIT_MODE_CONTACT } from '../action/contact';

export const contactList = (state = {}, action) => {
  switch (action.type) {
    case ADD_CONTACT_LIST:
      const newContact = {};
      newContact[action.accountId] = action.contactList;
      return {
        ...state,
        ...newContact,
      };

    case SET_EDIT_MODE_CONTACT:
      for (
        let i = 0;
        i < state[action.contact.account_id.toString()].length;
        i++
      ) {
        if (
          state[action.contact.account_id.toString()][i].id == action.contact.id
        ) {
          state[action.contact.account_id.toString()][i].isEdit = true;
        }
      }
      return { ...{}, ...state };
    default:
      return state;
  }
};
