import axios from '../util/CustomAxios';
import {
  setSelectedCustomer,
  getBillingCustomerAddress,
  getCustomerShppingAddress,
  getCustomer,
  getBillingCustomerDefaultAddress,
  setSelectedShippingAddress,
  setSelectedBillingAddress,
  getShippingCustomer,
  getDropShipContact,
} from './customer';

export const RECEIVE_SALESORDER_LIST = 'RECEIVE_SALESORDER_LIST';
export const SET_INITAIL_SALESORDER_REDUX_FORM_VALUE =
  'SET_INITAIL_SALESORDER_REDUX_FORM_VALUE';
export const SET_IS_SALESORDER_FORM_DATA_LOADING =
  'SET_IS_SALESORDER_FORM_DATA_LOADING';
export const SET_IS_FORWARDER = 'SET_IS_FORWARDER';
export const SET_TEST_FILTER_ENABLE = 'SET_TEST_FILTER_ENABLE';
export const SET_DATE_FILTER_ENABLE = 'SET_DATE_FILTER_ENABLE';
export const SET_SHIPMENT_DATE_FILTER_ENABLE =
  'SET_SHIPMENT_DATE_FILTER_ENABLE';
export const SET_ISSODATESUPDATECOMPLETE = 'SET_ISSODATESUPDATECOMPLETE';
export const SET_PICKUP_DATE_FILTER_ENABLE = 'SET_PICKUP_DATE_FILTER_ENABLE';

export function setIsForwarder(isForwarder) {
  return {
    type: SET_IS_FORWARDER,
    isForwarder: isForwarder,
  };
}

export function receiveSalesOrderList(salesOrderList) {
  return {
    type: RECEIVE_SALESORDER_LIST,
    salesOrderList: salesOrderList,
  };
}

export const getSalesOrderList = (Company) => (dispatch) => {
  return axios
    .get('/SalesOrders', { params: { Company: Company } })
    .then((response) => {
      dispatch(receiveSalesOrderList(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
};

export function setSalesOrderInitailReduxFormValue(initailValue) {
  return {
    type: SET_INITAIL_SALESORDER_REDUX_FORM_VALUE,
    salesOrderForm: initailValue,
  };
}

export function setIsSalesOrderFormDataLoading(isLoading) {
  return {
    type: SET_IS_SALESORDER_FORM_DATA_LOADING,
    isSalesOrderFormDataLoading: isLoading,
  };
}

export const getInvoiceFormData = (SOID) => (dispatch) => {
  dispatch(setIsSalesOrderFormDataLoading(true));
  return axios
    .get(`/Invoice/edit/${SOID}`)
    .then((response) => {
      const res = response.data;
      dispatch(setSalesOrderInitailReduxFormValue(InvoiceFormDataMapper(res)));
      dispatch(getCustomer(res.AccountID));
      dispatch(getShippingCustomer(res.shipping_account));
      dispatch(setSelectedBillingAddress(res.billingAddress[0]));
      dispatch(setSelectedShippingAddress(res.shippingAddress[0]));
      // dispatch(getCustomerDefaultAddress(res.AccountID))
      dispatch(setIsSalesOrderFormDataLoading(false));
    })
    .catch(function (error) {
      console.log(error);
    });
};

function InvoiceFormDataMapper(data) {
  data.SOIssueDate = new Date(data.created_date).toISOString().substring(0, 10);
  data.billingAccountID = data.billing_account;
  data.shippingAccountID = data.shipping_account;
  data.SOType = data.order_type;
  data.TermsOfDelivery = data.terms_of_delivery;
  data.DeliveryMethod = data.delivery_method;
  return data;
}

export const getSalesOrderFormData = (SOID) => async (dispatch) => {
  dispatch(setIsSalesOrderFormDataLoading(true));
  try {
    const response = await axios.get(`/SalesOrder/${SOID}`);
    const res = response.data[0];
    dispatch(setSalesOrderInitailReduxFormValue(SalesOrderFormDataMapper(res)));
    await Promise.all([
      dispatch(getCustomer(res.AccountID)),
      dispatch(getShippingCustomer(res.shipping_account)),
      dispatch(setSelectedBillingAddress(res.billingAddress)),
      dispatch(setSelectedShippingAddress(res.shippingAddress)),
      dispatch(getDropShipContact(res.AccountID, res.dropship_contact_id)),
    ]);
    dispatch(setIsSalesOrderFormDataLoading(false));
  } catch (error) {
    console.log(error);
    dispatch(setIsSalesOrderFormDataLoading(false));
  }
};

function SalesOrderFormDataMapper(data) {
  data.SONumber = Number(data.SONumber.slice(3).trim());
  data.SOIssueDate = new Date(data.SOIssueDate).toISOString().substring(0, 10);
  data.billingAccountID = data.AccountID;
  data.shippingAccountID = data.shipping_account;
  return data;
}

export function setTestFilterEnable(value) {
  return {
    type: SET_TEST_FILTER_ENABLE,
    isTestChecked: value,
  };
}

export function setDateFilterEnable(value) {
  console.log('action : ', value);
  return {
    type: SET_DATE_FILTER_ENABLE,
    dateForFilter: value,
  };
}

export function setShipmentDateFilterEnable(value) {
  console.log('action shipment ', value);
  return {
    type: SET_SHIPMENT_DATE_FILTER_ENABLE,
    dateForFilterShipment: value,
  };
}

export function setPickUpDateFilterEnable(value) {
  console.log('action pickup ', value);
  return {
    type: SET_PICKUP_DATE_FILTER_ENABLE,
    dateForFilterPickUp: value,
  };
}

export function setIsSODatesUpdateComplete(value) {
  return {
    type: SET_ISSODATESUPDATECOMPLETE,
    isSODatesUpdateComplete: value,
  };
}
