import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import { productList, productOrderList, discount } from './product';
import {
  salesOrderList,
  salesOrderFormValue,
  isSalesOrderFormDataLoading,
  searchValueToSalseOrder,
  isTestFilterChecked,
  isForwarder,
  isSODatesUpdateComplete,
  isDatePickerPicked,
  isShipmentDatePicked,
} from './salesOrder';
import {
  customerList,
  customerAddress,
  isCustomerShppingAddressSelectorOpen,
  isCustomerBillingAddressSelectorOpen,
  selectedCustomer,
  voltage,
  billingCountry,
  shippingCustomerID,
  selectedAddress,
  dropShipContact,
} from './customer';
import { accountList, addressList } from './account';
import { countryList } from './Country';
import { stateList } from './State';
import { company } from './user';
import {
  salesStatisticsData,
  selectedSalesStatisticsColumn,
  productStatisticsData,
  selectedProductStatisticsColumn,
} from './statistics';
import { contactList } from './contact';

export default combineReducers({
  salesOrderList,
  salesOrderFormValue,
  customerList,
  customerAddress,
  isCustomerShppingAddressSelectorOpen,
  selectedCustomer,
  voltage,
  productList,
  productOrderList,
  discount,
  billingCountry,
  shippingCustomerID,
  isSalesOrderFormDataLoading,
  isCustomerBillingAddressSelectorOpen,
  accountList,
  addressList,
  countryList,
  stateList,
  isTestFilterChecked,
  company,
  selectedAddress,
  isForwarder,
  isSODatesUpdateComplete,
  salesStatisticsData,
  selectedSalesStatisticsColumn,
  productStatisticsData,
  selectedProductStatisticsColumn,
  contactList,
  isDatePickerPicked,
  isShipmentDatePicked,
  dropShipContact,
  form: reduxFormReducer,
});
