import React from 'react';

const trueStyle = {
  background: '#21BA45',
  borderRadius: '10000px',
  height: '18px',
  color: 'white',
  padding: '4px 5px',
  cursor: 'pointer',
  margin: '0 2px',
};

const falseStyle = {
  background: 'gray',
  borderRadius: '10000px',
  height: '18px',
  color: 'white',
  padding: '4px 5px',
  cursor: 'pointer',
  margin: '0 2px',
};

const TrueFalseSign = ({ isTrue, trueMessage, falseMessage, onClick }) => {
  return (
    <span onClick={onClick} style={isTrue ? trueStyle : falseStyle}>
      {isTrue ? trueMessage : falseMessage}
    </span>
  );
};

export default TrueFalseSign;
