import React from 'react';
import FlagIcon from '../../../util/FlagFactory';

function extractCountryCode(country) {
  return country
    .substring(country.indexOf('(') + 1, country.indexOf('(') + 3)
    .toLowerCase();
}

const CountryWithFlag = ({ country }) => {
  return (
    <div style={{ width: '100%', flex: '0 100 auto' }}>
      <span>{country} </span>
      <FlagIcon code={extractCountryCode(country)} />
    </div>
  );
};

export default CountryWithFlag;
