import React, { Component, PropTypes } from 'react';

const outSideBoxStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 1,
};

export class OutsideClickCloser extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <div style={outSideBoxStyle} onClick={this.props.onClick}></div>;
  }
}
