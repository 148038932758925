import React from 'react';
import '../SalesOrder.css';

const CustomerContact = ({
  header,
  name,
  email,
  phone,
  modilePhone,
  note,
  isEditable,
  isAddable,
  isDeletable,
  editOnClick,
  addOnClick,
  deleteOnClick,
}) => {
  return (
    <div className="CustomerAddress" style={{ marginTop: '12px' }}>
      <h5 className="CustomerAddress__Header">{header}</h5>
      {isEditable ? (
        <a
          className="CustomerAddress__edit--button"
          onClick={(e) => {
            editOnClick(e);
          }}
        >
          edit
        </a>
      ) : null}
      {isAddable ? (
        <a
          className="CustomerAddress__add--button"
          onClick={(e) => {
            addOnClick(e);
          }}
        >
          add
        </a>
      ) : null}
      {isDeletable ? (
        <a
          className="CustomerAddress__delete--button"
          onClick={(e) => {
            deleteOnClick(e);
          }}
        >
          delete
        </a>
      ) : null}
      <p className="CustomerAddress__Contents">
        <strong className="CustomerAddress__AccountName">{name}</strong>
      </p>
      <p className="CustomerAddress__Contents">{email}</p>
      <p className="CustomerAddress__Contents">{phone}</p>
      <p className="CustomerAddress__Contents">{modilePhone}</p>
      <p className="CustomerAddress__Contents">{note}</p>
    </div>
  );
};

export default CustomerContact;
