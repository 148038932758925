import React, { PureComponent } from 'react';
import AccountTypeDropdown from '../Presenter/AccountTypeDropdown';
import Axios from '../../../util/CustomAxios';
import { patchAccountRequest } from '../../../action/account';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class AccountTypeDropDownContainer extends PureComponent {
  submitTypeChange = (type, onSuccess) => {
    this.props.patchAccountRequest(
      { TypeID: type },
      this.props.AccountID,
      onSuccess
    );
  };

  render() {
    return (
      <div>
        <AccountTypeDropdown
          {...this.props}
          submitTypeChange={this.submitTypeChange}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({}),
  (dispatch) => ({
    patchAccountRequest: bindActionCreators(patchAccountRequest, dispatch),
  })
)(AccountTypeDropDownContainer);
