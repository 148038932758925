import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AddressContextMenu } from '../Presenter/AddressContextMenu';
import { setEditModeAddress } from '../../../action/account';
import { setEditModeContact, deleteContact } from '../../../action/contact';

class ContactContextMenuContainer extends PureComponent {
  render() {
    return (
      <AddressContextMenu
        onEditButtonClick={() => {
          this.props.onEditButtonClick(this.props.ri.original);
        }}
        onDeleteButtonClick={() => {
          this.props.onDeleteButtonClick(
            this.props.ri.original.id,
            this.props.ri.original.account_id
          );
        }}
        address={this.props.ri.original}
        id={'Contact' + this.props.ri.original.id}
      />
    );
  }
}

export default connect(
  (state) => ({}),
  (dispatch) => ({
    onEditButtonClick: bindActionCreators(setEditModeContact, dispatch),
    onDeleteButtonClick: bindActionCreators(deleteContact, dispatch),
  })
)(ContactContextMenuContainer);
