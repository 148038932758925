import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { MoneyInput } from './customComponents';
import NumberFormat from 'react-number-format';
import { Table } from 'semantic-ui-react';

const propTypes = {};

const defaultProps = {};

class SubTotal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Table.Footer fullWidth>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell style={{ textAlign: 'end' }}>
            {this.props.subQty}
          </Table.HeaderCell>
          <Table.HeaderCell />
          <Table.HeaderCell>
            <div class="ui input">
              <NumberFormat
                style={{ width: '100%', textAlign: 'right' }}
                thousandSeparator={true}
              />
            </div>
          </Table.HeaderCell>
          <Table.HeaderCell />
          <Table.HeaderCell style={{ textAlign: 'end' }}>
            <NumberFormat
              style={{ width: '100%', textAlign: 'right' }}
              thousandSeparator={true}
              value={this.props.subTotal}
              prefix={this.props.currency == 'KRW' ? '₩' : '$'}
              displayType={'text'}
            />
          </Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Footer>
    );
  }
}

SubTotal.PropTypes = propTypes;
SubTotal.defaultProps = defaultProps;

export default SubTotal;
