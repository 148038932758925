import React from 'react';
import NumberFormat from 'react-number-format';

const Total = ({ total, currency }) => {
  return (
    <div style={{ width: '100%' }}>
      <h3 style={{ textAlign: 'right', marginRight: '14px' }}>
        <NumberFormat
          value={total}
          displayType={'text'}
          thousandSeparator={true}
          prefix={currency == 'KRW' ? '₩' : '$'}
        />
      </h3>
    </div>
  );
};

export default Total;
