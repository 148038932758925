import React from 'react';
import { Component } from 'react';
import { Bar, defaults } from 'react-chartjs-2';
import { NumberFormat } from 'react-number-format';

var MonthData = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

class SalesStatisticsBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }
  getIntMonthFromStringMonth(stringMonth) {
    let count = 1;
    let result = 0;
    MonthData.forEach((month) => {
      if (month == stringMonth) result = count;
      else count += 1;
    });
    return result;
  }
  chageDataAcordingToCurrency(dataArray, currency) {
    if (currency.trim() == 'KRW') {
      return dataArray.map((salesOrder) => {
        if (salesOrder.Currency.trim() == 'USD')
          return {
            ...salesOrder,
            Amount: salesOrder.Amount * 1060,
          };
        else if (salesOrder.Currency.trim() == 'CNY') return false;
        else return salesOrder;
      });
    } else {
      return dataArray.map((salesOrder) => {
        if (salesOrder.Currency.trim() == 'KRW') {
          return {
            ...salesOrder,
            Amount: Math.round(Number(salesOrder.Amount / 1060)),
          };
        } else if (salesOrder.Currency.trim() == 'CNY') return false;
        else return salesOrder;
      });
    }
  }
  makeBarChartData(dataArray, currency) {
    let Amount = [];
    let KRWAmount = [];
    let USDAmount = [];
    let packedData = [];
    let pickedUpData = [];
    let shippedData = [];
    let soIssuedData = [];
    let testCompleted = [];

    this.chageDataAcordingToCurrency(dataArray, currency).forEach((data) => {
      let month = new Date(data.SOIssueDate).getMonth();
      if (data.Status == 1)
        if (packedData[month] == undefined) {
          packedData[month] = 1;
        } else {
          packedData[month] += 1;
        }
      else if (data.Status == 2)
        if (pickedUpData[month] == undefined) {
          pickedUpData[month] = 1;
        } else {
          pickedUpData[month] += 1;
        }
      else if (data.Status == 3) {
        if (shippedData[month] == undefined) shippedData[month] = 1;
        else shippedData[month] += 1;

        let shippedMonth = new Date(data.DateofShipment).getMonth();
        if (Amount[shippedMonth] == undefined)
          Amount[shippedMonth] = data.Amount;
        else Amount[shippedMonth] += data.Amount;
        console.log(Amount[0]);
      } else if (data.Status == 4)
        if (soIssuedData[month] == undefined) {
          soIssuedData[month] = 1;
        } else {
          soIssuedData[month] += 1;
        }
      else if (data.Status == 5)
        if (testCompleted[month] == undefined) {
          testCompleted[month] = 1;
        } else {
          testCompleted[month] += 1;
        }
    });

    dataArray.forEach((data) => {
      let month = new Date(data.DateofShipment).getMonth();

      if (data.Status == 3) {
        if (data.Currency.trim() == 'KRW')
          if (KRWAmount[month] == undefined) KRWAmount[month] = data.Amount;
          else KRWAmount[month] += data.Amount;
        else if (data.Currency.trim() == 'USD')
          if (USDAmount[month] == undefined) USDAmount[month] = data.Amount;
          else USDAmount[month] += data.Amount;
      }
    });

    console.log(Amount, KRWAmount, USDAmount);
    return {
      packedData,
      pickedUpData,
      shippedData,
      soIssuedData,
      testCompleted,
      Amount,
      KRWAmount,
      USDAmount,
    };
  }
  toDataFormat(data) {
    let formattedData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    if (data != undefined) {
      for (let index = 0; index < data.length; index++) {
        formattedData[index] = data[index];
      }
    }
    return formattedData;
  }

  componentWillMount() {
    this.props.getSelectedBar(13, 'SOIssued', 'ALL');
    this.setState({
      data: this.makeBarChartData(this.props.data, this.props.currency),
    });
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.data != nextProps.data ||
      this.props.currency != nextProps.currency
    ) {
      this.setState({
        data: this.makeBarChartData(nextProps.data, nextProps.currency),
      });
    }
  }

  chartOption2Helper(currency) {
    let currencyMark = '';

    if (currency.trim() == 'KRW') currencyMark = '￦';
    else if (currency.trim() == 'USD') currencyMark = '$';
    else currencyMark = '$';

    return {
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            stacked: false,
          },
        ],
        yAxes: [
          {
            stacked: false,
            ticks: {
              callback: function (label, index, labels) {
                if (label < 1000) return currencyMark + '' + label;
                else if (1000 <= label && label < 1000000)
                  return currencyMark + '' + label / 1000 + 'K';
                else if (1000000 <= label && label < 1000000000)
                  return currencyMark + '' + label / 1000000 + 'M';
                else if (1000000000 <= label && label < 1000000000000)
                  return currencyMark + '' + label / 1000000000 + 'G';
                else return currencyMark + '' + label;
              },
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            var label = data.datasets[tooltipItem.datasetIndex].label || '';

            if (label) {
              label += ' : ';
            }
            label +=
              currencyMark +
              '' +
              new Intl.NumberFormat().format(
                Math.round(tooltipItem.yLabel * 100) / 100
              );
            return label;
          },
        },
      },
    };
  }
  chartData3Helper(data, currency) {
    let KRW = [];
    let USD = [];
    if (currency.trim() == 'KRW') {
      KRW = this.toDataFormat(data.KRWAmount);
      USD = this.toDataFormat(data.USDAmount).map((value) => {
        return Number(value * 1060);
      });
    } else if (currency.trim() == 'USD') {
      KRW = this.toDataFormat(data.KRWAmount).map((value) => {
        return Math.round(Number(value / 1060));
      });
      USD = this.toDataFormat(data.USDAmount);
    } else {
      KRW = this.toDataFormat(data.KRWAmount).map((value) => {
        return Math.round(Number(value / 1060));
      });
      USD = this.toDataFormat(data.USDAmount);
    }
    return {
      labels: MonthData,
      datasets: [
        {
          label: 'KRW',
          backgroundColor: 'rgba(97, 213, 98, 0.5)',
          borderColor: 'rgba(97, 213, 98, 0.8)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(97, 213, 98, 0.75)',
          hoverBorderColor: 'rgba(97, 213, 98, 1)',
          data: KRW,
        },
        {
          label: 'USD',
          backgroundColor: 'rgba(82, 179, 255, 0.5)',
          borderColor: 'rgba(82, 179, 255, 0.8)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(82, 179, 255, 0.75)',
          hoverBorderColor: 'rgba(82, 179, 255, 1)',
          data: USD,
        },
      ],
    };
  }
  chartOption3Helper(currency) {
    let currencyMark = '';
    if (currency.trim() == 'KRW') currencyMark = '￦';
    else if (currency.trim() == 'USD') currencyMark = '$';
    else currencyMark = '$';

    return {
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            stacked: true,
          },
        ],
        yAxes: [
          {
            stacked: true,
            ticks: {
              callback: function (label, index, labels) {
                if (label < 1000) return currencyMark + '' + label;
                else if (1000 <= label && label < 1000000)
                  return currencyMark + '' + label / 1000 + 'K';
                else if (1000000 <= label && label < 1000000000)
                  return currencyMark + '' + label / 1000000 + 'M';
                else if (1000000000 <= label && label < 1000000000000)
                  return currencyMark + '' + label / 1000000000 + 'G';
                else return currencyMark + '' + label;
              },
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            var label = data.datasets[tooltipItem.datasetIndex].label || '';
            if (label) {
              label += ' : ';
            }
            if (label == 'KRW : ')
              label +=
                currencyMark +
                '' +
                new Intl.NumberFormat().format(
                  Math.round(tooltipItem.yLabel * 100) / 100
                );
            else
              label +=
                currencyMark +
                '' +
                new Intl.NumberFormat().format(
                  Math.round(tooltipItem.yLabel * 100) / 100
                );
            return label;
          },
        },
      },
    };
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  render() {
    var chartData = {};
    var chartOption = {};
    var yearlySales = 0;
    if (this.state.data != undefined) {
      yearlySales =
        this.state.data.Amount &&
        this.state.data.Amount.reduce((a, b) => a + b, 0);

      if (this.props.type == 'Compare') {
        chartData = this.chartData3Helper(this.state.data, this.props.currency);
        chartOption = this.chartOption3Helper(this.props.currency);
      } else if (this.props.type == 'Amount') {
        chartData = {
          labels: MonthData,
          datasets: [
            this.props.currency == 'KRW'
              ? {
                  label: 'Amount',
                  backgroundColor: 'rgba(46, 196, 182, 0.5)',
                  borderColor: 'rgba(46, 196, 182, 0.8)',
                  borderWidth: 1,
                  hoverBackgroundColor: 'rgba(46, 196, 182, 0.75)',
                  highlihoverBorderColorghtStroke: 'rgba(46, 196, 182, 1)',
                  data: this.toDataFormat(this.state.data.Amount),
                }
              : {
                  label: 'Amount',
                  backgroundColor: 'rgba(82, 179, 255, 0.5)',
                  borderColor: 'rgba(82, 179, 255, 0.8)',
                  borderWidth: 1,
                  hoverBackgroundColor: 'rgba(82, 179, 255, 0.75)',
                  hoverBorderColor: 'rgba(82, 179, 255, 1)',
                  data: this.toDataFormat(this.state.data.Amount),
                },
          ],
        };
        chartOption = this.chartOption2Helper(this.props.currency);
      } else if (this.props.type == 'Status') {
        chartData = {
          labels: MonthData,
          datasets: [
            {
              label: 'SOIssued',
              backgroundColor: 'rgba(229,114, 105, 0.5)',
              borderColor: 'rgba(229,114, 105, 0.8)',
              borderWidth: 1,
              hoverBackgroundColor: 'rgba(229,114, 105, 0.75)',
              hoverBorderColor: 'rgba(229,114, 105, 1)',
              data: this.toDataFormat(this.state.data.soIssuedData),
            },
            {
              label: 'Test Completed',
              backgroundColor: 'rgba(255, 209, 92, 0.5)',
              borderColor: 'rgba(255, 209, 92, 0.8)',
              hoverBackgroundColor: 'rgba(255, 209, 92, 0.75)',
              hoverBorderColor: 'rgba(255, 209, 92, 1)',
              data: this.toDataFormat(this.state.data.testCompleted),
            },
            {
              label: 'Packed',
              backgroundColor: 'rgba(97, 213, 98, 0.5)',
              borderColor: 'rgba(97, 213, 98, 0.8)',
              borderWidth: 1,
              hoverBackgroundColor: 'rgba(97, 213, 98, 0.75)',
              hoverBorderColor: 'rgba(97, 213, 98, 1)',
              data: this.toDataFormat(this.state.data.packedData),
            },
            {
              label: 'Picked Up',
              backgroundColor: 'rgba(82, 179, 255, 0.5)',
              borderColor: 'rgba(82, 179, 255, 0.8)',
              borderWidth: 1,
              hoverBackgroundColor: 'rgba(82, 179, 255, 0.75)',
              hoverBorderColor: 'rgba(82, 179, 255, 1)',
              data: this.toDataFormat(this.state.data.pickedUpData),
            },
            {
              label: 'Shipped',
              backgroundColor: 'rgba(149,123, 199, 0.5)',
              borderColor: 'rgba(149,123, 199, 0.8)',
              borderWidth: 1,
              hoverBackgroundColor: 'rgba(149,123, 199, 0.75)',
              hoverBorderColor: 'rgba(149,123, 199, 1)',
              data: this.toDataFormat(this.state.data.shippedData),
            },
          ],
        };
        chartOption = {
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                stacked: false,
              },
            ],
            yAxes: [
              {
                stacked: false,
                ticks: {
                  callback: function (label, index, labels) {
                    if (label < 1000) return label;
                    else if (1000 <= label && label < 1000000)
                      return label / 1000 + 'K';
                    else if (1000000 <= label && label < 1000000000)
                      return label / 1000000 + 'M';
                    else if (1000000000 <= label && label < 1000000000000)
                      return label / 1000000000 + 'G';
                    else return label;
                  },
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                var label = data.datasets[tooltipItem.datasetIndex].label || '';

                if (label) {
                  label += ' : ';
                }
                label += new Intl.NumberFormat().format(
                  Math.round(tooltipItem.yLabel * 100) / 100
                );
                return label;
              },
            },
          },
        };
      }
    }

    return (
      <div>
        <p>
          Yearly Sales: {this.numberWithCommas(yearlySales)}{' '}
          {this.props.currency}
        </p>
        <div>
          <Bar
            data={chartData}
            options={chartOption}
            width={100}
            height={250}
            getElementAtEvent={(elems) => {
              if (elems.length > 0) {
                if (this.props.type == 'Compare')
                  this.props.getSelectedBar(
                    this.getIntMonthFromStringMonth(elems[0]._model.label),
                    'Shipped',
                    elems[0]._model.datasetLabel
                  );
                else if (this.props.type == 'Amount')
                  this.props.getSelectedBar(
                    this.getIntMonthFromStringMonth(elems[0]._model.label),
                    'Shipped',
                    'ALL'
                  );
                else if (this.props.type == 'Status')
                  this.props.getSelectedBar(
                    this.getIntMonthFromStringMonth(elems[0]._model.label),
                    elems[0]._model.datasetLabel,
                    'ALL'
                  );
              }
            }}
          />
        </div>
      </div>
    );
  }
}

export default SalesStatisticsBar;
