export default {
  A10: 'rgba(249,161,27,',
  A10Remote: 'rgba(253,194,62,',
  CastFi_7: 'rgba(255,95,46,',
  Chromcast1: 'rgba(253,119,97,',
  CS_Parts: 'rgba(249,205,173,',
  dCSDongle: 'rgba(53,56,102,',
  FLOW: 'rgba(117,215,1,',
  HDDUpgrade: 'rgba(121,168,169,',
  'Labor Charge': 'rgba(117,79,68,',
  'MiniHDMedia Box': 'rgba(139,134,135,',
  N10: 'rgba(250,177,206,',
  N100C: 'rgba(238,110,159,',
  N100H: 'rgba(239,82,133,',
  S10: 'rgba(216,17,89,',
  S5B: 'rgba(163,218,255,',
  S5W: 'rgba(3,166,255,',
  SpeakerStand: 'rgba(0,128,255,',
  UC100: 'rgba(211,153,185,',
  UT100: 'rgba(144,68,162,',
  W20: 'rgba(165,147,224,',
  X100L: 'rgba(168,219,168,',
  X725: 'rgba(59,134,134,',
};
