import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getContactList,
  insertContact,
  updateContact,
} from '../../../action/contact';
import ContactList from '../Presenter/ContactList';

class ContactListContainer extends Component {
  constructor(props) {
    super(props);

    this.onContactAddButtonOnclick = this.onContactAddButtonOnclick.bind(this);
  }
  componentDidMount() {
    if (!this.props.contactList[this.props.AccountID]) {
      this.props.getContactList(this.props.AccountID);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.AccountID != nextProps.AccountID) return true;
    if (
      nextProps.contactList[this.props.AccountID] !=
      this.props.contactList[this.props.AccountID]
    ) {
      return true;
    }
    if (
      this.props.countryList != nextProps.countryList ||
      this.props.stateList != nextProps.stateList
    )
      return true;
    return false;
  }

  onContactAddButtonOnclick(newContact) {
    this.props.insertContact(newContact);
  }

  render() {
    return (
      <ContactList
        {...this.props}
        onContactAddButtonOnclick={this.onContactAddButtonOnclick}
        contactList={this.props.contactList[this.props.AccountID]}
        AccountID={this.props.AccountID}
        updateContact={this.props.updateContact}
      />
    );
  }
}

export default connect(
  (state) => ({
    contactList: state.contactList,
  }),
  (dispatch) => ({
    getContactList: bindActionCreators(getContactList, dispatch),
    insertContact: bindActionCreators(insertContact, dispatch),
    updateContact: bindActionCreators(updateContact, dispatch),
  })
)(ContactListContainer);
