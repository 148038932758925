import axios from '../util/CustomAxios';
export const SET_COUNTRY_LIST = 'SET_COUNTRY_LIST';

export function setCountrytList(countryList) {
  return {
    type: SET_COUNTRY_LIST,
    countryList: countryList,
  };
}

export const getCountryList = (state) => (dispatch) => {
  return axios
    .get('/Country')
    .then((response) => {
      dispatch(setCountrytList(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
};
