import React from 'react';
import CountrySimpleDropdown from './CountrySimpleDropdown';
import SimpleDropdown from './SimpleDropdown';
import SimpleMulipleDropdown from './SimpleMulipleDropdown';

const styles = {
  popup: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  popupInner: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '5px',
    maxWidth: '500px',
    width: '100%',
  },
  formGroup: {
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
  },
  input: {
    width: '100%',
    padding: '5px',
    fontSize: '14px',
  },
  errorInput: {
    border: '1px solid red',
  },
  button: {
    marginRight: '10px',
    padding: '5px 10px',
    fontSize: '14px',
    cursor: 'pointer',
  },
};

const USA_CODE = 2;

const billingShippingOptions = [
  { label: 'billing', value: 'billing' },
  { label: 'shipping', value: 'shipping' },
];

const AddressInputModal = ({
  newAddress,
  validationError,
  countryList,
  stateList,
  onNewAddressChange,
  onNewCountryChange,
  onNewTypeChange,
  onNewAddressSubmit,
  onCancel,
}) => {
  const {
    country,
    address,
    city,
    state,
    type,
    postal_code,
    selectedCountry,
    attention_input,
  } = newAddress;

  return (
    <div style={styles.popup}>
      <div style={styles.popupInner}>
        <h2>New Address</h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onNewAddressSubmit();
          }}
        >
          <div style={styles.formGroup}>
            <label style={styles.label}>Country:</label>
            <CountrySimpleDropdown
              error={validationError.country}
              list={countryList}
              value={selectedCountry.Name}
              name="country"
              onChange={onNewCountryChange}
            />
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label}>State:</label>
            {/* USA로 선택되면 state dropdown으로 변경 */}
            {selectedCountry.CountryID !== USA_CODE ? (
              <input
                style={styles.input}
                type="text"
                name="state"
                value={state}
                onChange={onNewAddressChange}
              />
            ) : (
              <SimpleDropdown
                list={stateList.map((state) => ({
                  Name: `${state.name.trim()} (${state.code})`,
                }))}
                value={state}
                name="state"
                onChange={onNewAddressChange}
              />
            )}
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label}>City:</label>
            <input
              style={styles.input}
              type="text"
              name="city"
              value={city}
              onChange={onNewAddressChange}
            />
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label}>Address:</label>
            <input
              style={{
                ...styles.input,
                ...(validationError.address ? styles.errorInput : {}),
              }}
              type="text"
              name="address"
              value={address}
              onChange={onNewAddressChange}
            />
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label}>Postal Code:</label>
            <input
              style={styles.input}
              type="text"
              name="postal_code"
              value={postal_code}
              onChange={onNewAddressChange}
            />
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label}>Attention Input:</label>
            <input
              style={styles.input}
              type="text"
              name="attention_input"
              value={attention_input}
              onChange={onNewAddressChange}
            />
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label}>Type:</label>
            <SimpleMulipleDropdown
              simpleValue={true}
              clearable={false}
              multi={true}
              name="type"
              value={type}
              onChange={onNewTypeChange}
              options={billingShippingOptions}
            />
          </div>
          <button style={styles.button} type="submit">
            Add
          </button>
          <button style={styles.button} type="button" onClick={onCancel}>
            Cancel
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddressInputModal;
