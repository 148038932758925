import withGetArrayData from '../../../HOCs/withGetArrayData';
import DataDropdown from './DataDropdown';
import withReduxFormValidation from '../../../HOCs/withReduxFormValidation';

const TermsofDelivery = withGetArrayData(
  DataDropdown,
  '/SalesOrderTermsofDelivery',
  (array) =>
    array.map((item) => {
      return {
        key: item.SODTID,
        text: item.Name,
        value: item.SODTID,
      };
    }),
  {
    company: localStorage.getItem('NowCompany'),
  }
);

export default withReduxFormValidation(TermsofDelivery);
