import React, { PureComponent, PropTypes } from 'react';
import PropTypes_ from 'prop-types';
import { Label, Grid } from 'semantic-ui-react';
import CustomerDropbox from './CustomerDropbox';
import axios from '../../../util/CustomAxios';
import BillingCustomerDetailContainer from '../containers/BillingCustomerDetailContainer';
import ShippingCustomerDetailContainer from '../containers/ShippingCustomerDetailContainer';
import EditAccountBtn from './EditAccountBtn';

const propTypes = {
  customerList: PropTypes_.arrayOf(
    PropTypes_.shape({
      Name: PropTypes_.string.isRequired,
      AccountID: PropTypes_.number.isRequired,
      Currency: PropTypes_.string,
    })
  ).isRequired,
};

const defaultProps = {
  customerList: [],
};

function createWarning(funcName) {
  return () => console.warn((funcName = ' is not defined'));
}

class CustomerSelector extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null,
    };

    this.customerDropboxOnChangeHandler =
      this.customerDropboxOnChangeHandler.bind(this);
    this.billingCustomerOnChangeHandler =
      this.billingCustomerOnChangeHandler.bind(this);
  }

  customerDropboxOnChangeHandler(selectedItemID) {
    const selectedItem = this.props.customerList.find(
      (customer) => customer.AccountID == selectedItemID
    );
    this.setState({
      selectedItem,
    });
  }

  billingCustomerOnChangeHandler = (event, data) => {
    const selectedAccountID = data.value;
    this.props.fields.billingAccountID.input.onChange(selectedAccountID);
    axios.get(`/Address/${selectedAccountID}`).then((response) => {
      this.props.fields.bill_to.input.onChange(
        response.data[0].default_billing_address
      );
    });
    this.customerDropboxOnChangeHandler(selectedAccountID);

    // Old Address
    this.props.getBillingCustomerAddress(selectedAccountID);

    // New Address
    this.props.getBillingCustomerDefaultAddress(selectedAccountID);

    this.props.closeCustomerBillingAddressSelector();
    this.props.setSelectedBillingCustomer(
      this.props.customerList.filter((customer) => {
        if (customer.AccountID == selectedAccountID) {
          return true;
        }
      })[0]
    );

    this.props.setSelectedBillingAddress();

    if (!this.props.fields.shippingAccountID.input.value) {
      this.shippingCustomerOnChangeHandler(event, data);
    }
  };

  shippingCustomerOnChangeHandler = (event, data) => {
    const selectedAccountID = data.value;
    this.props.fields.shippingAccountID.input.onChange(selectedAccountID);
    axios.get(`/Address/${selectedAccountID}`).then((response) => {
      this.props.fields.ship_to.input.onChange(
        response.data[0].default_shipping_address
      );
    });

    // Old Address
    this.props.getShippingCustomerAddress(selectedAccountID);

    // New Address
    this.props.getShippingCustomerDefaultAddress(selectedAccountID);

    this.props.closeCustomerShppingAddressSelector();
    this.props.setSelectedShippingCustomer(
      this.props.customerList.filter((customer) => {
        if (customer.AccountID == selectedAccountID) {
          return true;
        }
      })[0]
    );

    this.props.setSelectedShippingAddress();
  };

  render() {
    const { customerList, fields } = this.props;
    return (
      <React.Fragment>
        <Grid.Column width={5} verticalAlign="top">
          <CustomerDropbox
            fields={fields}
            customerList={customerList}
            onChange={this.billingCustomerOnChangeHandler}
            input={fields.billingAccountID.input}
            meta={fields.billingAccountID.meta}
          />
          {/* Billing Address */}
          <EditAccountBtn accountId={fields.billingAccountID.input.value} />
          <BillingCustomerDetailContainer fields={fields} />
        </Grid.Column>
        <Grid.Column width={5} verticalAlign="top">
          <CustomerDropbox
            fields={fields}
            customerList={customerList}
            onChange={this.shippingCustomerOnChangeHandler}
            input={fields.shippingAccountID.input}
            meta={fields.shippingAccountID.meta}
          />
          {/* Shipping Address */}
          <EditAccountBtn accountId={fields.shippingAccountID.input.value} />
          <ShippingCustomerDetailContainer fields={fields} />
        </Grid.Column>
        <Grid.Column width={3} verticalAlign="top">
          <Label size="big" style={{ textAlign: 'center' }}>
            {this.state.selectedItem != null
              ? this.state.selectedItem.Currency
              : 'USD'}
          </Label>
        </Grid.Column>
      </React.Fragment>
    );
  }
}

CustomerSelector.PropTypes = propTypes;
CustomerSelector.defaultProps = defaultProps;

export default CustomerSelector;
