import React, { useRef, forwardRef } from 'react';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';

const ReactTableWithContextMenu = (Component) => {
  const RTTreeTable = (props) => {
    const wrappedInstanceRef = useRef(null);

    const getWrappedInstance = () => {
      const wrappedInstance = wrappedInstanceRef.current;
      if (!wrappedInstance) {
        console.warn('RTTreeTable - No wrapped instance');
        return null;
      }

      return wrappedInstance.getWrappedInstance
        ? wrappedInstance.getWrappedInstance()
        : wrappedInstance;
    };

    const TrComponent = (componentProps) => {
      const { ri, ...rest } = componentProps;
      if (ri) {
        return (
          <React.Fragment>
            <ContextMenuTrigger id={props.contextMenuName + ri.original.id}>
              <Component.defaultProps.TrComponent {...rest} />
            </ContextMenuTrigger>
            {props.contextMenuComponent && (
              <props.contextMenuComponent {...componentProps} />
            )}
          </React.Fragment>
        );
      }
      return <Component.defaultProps.TrComponent {...rest} />;
    };

    const getTrProps = (state, ri, ci, instance) => {
      return { ri };
    };

    const { columns, treeTableIndent, ...rest } = props;

    const extraColumns = columns.map((col) => {
      if (rest.pivotBy && rest.pivotBy.includes(col.accessor)) {
        return {
          accessor: col.accessor,
          width: `${treeTableIndent}px`,
          show: false,
          Header: '',
        };
      }
      return col;
    });

    return (
      <Component
        {...rest}
        columns={extraColumns}
        TrComponent={TrComponent}
        getTrProps={getTrProps}
        ref={wrappedInstanceRef}
      />
    );
  };

  RTTreeTable.displayName = 'RTTreeTable';
  RTTreeTable.defaultProps = { treeTableIndent: 10 };

  return RTTreeTable;
};

export default ReactTableWithContextMenu;
