import React, { PureComponent, PropTypes } from 'react';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import withReduxFormValidation from '../../../HOCs/withReduxFormValidation';
import withGetArrayData from '../../../HOCs/withGetArrayData';

class DataDropdwon extends PureComponent {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }
  onChange(event, data) {
    this.props.input.onChange(data.value);
  }
  render() {
    const { labelName, data, input } = this.props;
    return (
      <Dropdown
        placeholder="Type"
        fluid
        search
        selection
        options={data}
        {...this.props}
        value={input.value}
        onChange={this.onChange}
      />
    );
  }
}

export default DataDropdwon;
