import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CustomerBillingAdress from '../presenters/CustomerBillingAdress';
import CustomerShippingAdress from '../presenters/CustomerShippingAdress';
import {
  openCustomerShppingAddressSelector,
  closeCustomerShppingAddressSelector,
} from '../../../action/customer';
import '../SalesOrder.css';
import Total from '../presenters/Total';
import getFormValues from 'redux-form/lib/getFormValues';

const TotalContainer = ({
  subTotal,
  adjustment,
  shippingCharge,
  currency,
  salesOrderType,
}) => {
  const total = Number(subTotal) + Number(shippingCharge) + Number(adjustment);
  const finalTotal = salesOrderType === 8 ? -Math.abs(total) : total;

  return (
    <div className="CustomerDetail">
      <Total total={finalTotal} currency={currency.trim()} />
    </div>
  );
};

const toIntegerHelper = (num) => {
  let valueString = num.toString();
  let value = valueString.split('.')[1];
  return String(value).length;
};

const calculateDiscount = (discount) => {
  var T1 = Number('1e' + toIntegerHelper(discount));
  var T2 = Number('1e' + toIntegerHelper((100 * T1 - discount * T1) / T1));
  var returnDiscount =
    Math.floor(((100 * T1 - discount * T1) / T1) * T2) / (100 * T2);

  return returnDiscount;
};

export default connect((state) => ({
  subTotal: state.productOrderList.reduce((a, b) => {
    let amount;
    if (
      state.selectedCustomer.billingCustomer.Currency != undefined &&
      state.selectedCustomer.billingCustomer.Currency.trim() == 'KRW'
    ) {
      amount = Number(
        (
          b.product.KRWRetail *
          Math.abs(b.quantity) *
          calculateDiscount(b.discount)
        ).toFixed(0)
      );
    } else {
      amount = Number(
        (
          b.product.USDRetail *
          Math.abs(b.quantity) *
          calculateDiscount(b.discount)
        ).toFixed(2)
      );
    }
    return a + (state.salesOrderType === 8 ? -Math.abs(amount) : amount);
  }, 0),
  customerAddress: state.customerAddress,
  isCustomerShppingAddressSelectorOpen:
    state.isCustomerShppingAddressSelectorOpen,
  adjustment: !!getFormValues('SalesOrder')(state)
    ? getFormValues('SalesOrder')(state).Adjustment
    : 0,
  shippingCharge: !!getFormValues('SalesOrder')(state)
    ? getFormValues('SalesOrder')(state).ShippingCharge
    : 0,
  currency:
    state.selectedCustomer.billingCustomer.Currency != undefined
      ? state.selectedCustomer.billingCustomer.Currency
      : 'USD',
}))(TotalContainer);
