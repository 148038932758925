import React from 'react';
import FlagIcon from '../../../util/FlagFactory';
import axios from '../../../util/CustomAxios';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import Autocomplete from 'react-autocomplete';

function extractCountryCode(country) {
  return country
    .substring(country.indexOf('(') + 1, country.indexOf('(') + 3)
    .toLowerCase();
}

const COUNTRY_MENU_STYLE = {
  borderRadius: '3px',
  boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
  background: 'rgba(255, 255, 255, 0.9)',
  padding: '2px 0',
  fontSize: '90%',
  position: 'absolute',
  overflow: 'auto',
  maxHeight: '300px',
  zIndex: 10,
  top: '35px',
  left: 0,
};

const CountrySimpleDropdown = ({
  cellInfo,
  onChange = () => {},
  onSelect = () => {},
  value = '',
  list = [],
  name,
  error,
}) => {
  return (
    <Autocomplete
      wrapperStyle={{ width: '100%', position: 'relative' }}
      menuStyle={COUNTRY_MENU_STYLE}
      getItemValue={(item) => item.Name}
      items={list}
      shouldItemRender={(item, value) =>
        item.Name.toLowerCase().indexOf(value.toLowerCase()) > -1
      }
      renderInput={(props) => (
        <input
          style={
            error
              ? { border: 'solid 1px red', width: '100%' }
              : { width: '100%' }
          }
          name={name}
          {...props}
        />
      )}
      renderItem={(item, highlighted) => (
        <div
          key={item.CountryID}
          style={{
            backgroundColor: highlighted ? '#eee' : 'transparent',
          }}
        >
          {item.Name}
        </div>
      )}
      value={value}
      onChange={(e) =>
        onChange({ target: { value: { Name: e.target.value } } })
      }
      onSelect={(value, item) =>
        onChange({ target: { name: name, value: item } })
      }
    />
  );
};

export default CountrySimpleDropdown;
