import ReactTable from 'react-table';

import React, { Component } from 'react';

const matchModelColor = [
  {
    text: 'Packed',
    value: 1,
  },
  {
    text: 'Picked Up',
    value: 2,
  },
  {
    text: 'Shipped',
    value: 3,
  },
  {
    text: 'SOIssued',
    value: 4,
  },
  {
    text: 'Test Completed',
    value: 5,
  },
];

class ProductStatisticsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isFirst: true,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.data != nextProps.data) {
      this.setState({
        data: this.filterDataAccordingToSelectedBar(
          nextProps.data,
          nextProps.selectedBar
        ),
      });
    }
    if (
      this.props.selectedBar != nextProps.selectedBar &&
      nextProps.selectedBar.month != undefined &&
      nextProps.selectedBar.model != undefined
    ) {
      this.setState({
        data: this.filterDataAccordingToSelectedBar(
          nextProps.data,
          nextProps.selectedBar
        ),
        isFirst: false,
      });
    }
  }

  filterDataAccordingToSelectedBar(dataArray, selectedBar) {
    console.log(dataArray, selectedBar);
    let filterdData =
      selectedBar.month == 13
        ? dataArray.filter((data) => {
            if (selectedBar.model == 'ALL') return true;
            else if (selectedBar.model.trim() == data.Model.trim()) return true;
            return false;
          })
        : dataArray.filter((data) => {
            if (
              selectedBar.month ==
              new Date(data.DateofShipment).getMonth() + 1
            ) {
              if (selectedBar.model == 'ALL') return true;
              else if (selectedBar.model.trim() == data.Model.trim())
                return true;
              return false;
            }
            return false;
          });
    console.log(filterdData);
    return filterdData;
  }

  makeChartTableData(dataArray) {
    return dataArray.map((data) => {
      return {
        soNumber: data.SONumber.trim(),
        soIssueDate: new Date(data.SOIssueDate).toISOString().substring(0, 10),
        dateOfShipment:
          data.DateofShipment != null
            ? new Date(data.DateofShipment).toISOString().substring(0, 10)
            : null,
        customer: data.Name.trim(),
        productCode: data.ProductCode.trim(),
        serial: data.Serial,
      };
    });
  }

  render() {
    const columns = [
      {
        Header: 'SO Number',
        accessor: 'soNumber',
        maxWidth: 130,
        sortMethod: (a, b) => {
          if (a.length === b.length) {
            return a > b ? 1 : -1;
          }
          return a.length > b.length ? 1 : -1;
        },
      },
      {
        Header: 'SO Issue Date',
        accessor: 'soIssueDate',
        maxWidth: 130,
      },
      {
        Header: 'Date of Shipment',
        accessor: 'dateOfShipment',
        maxWidth: 130,
      },
      {
        Header: 'Customer',
        accessor: 'customer',
      },
      {
        Header: 'Product Code',
        accessor: 'productCode',
        maxWidth: 150,
      },
      {
        Header: 'Serial',
        accessor: 'serial',
        maxWidth: 120,
      },
    ];
    return (
      <ReactTable
        data={this.makeChartTableData(this.state.data)}
        columns={columns}
        style={{ height: '600px' }}
        defaultPageSize={20}
      />
    );
  }
}

export default ProductStatisticsTable;
