import axios from '../util/CustomAxios';
export const REQUEST_CUSTOMER_LIST = 'REQUEST_CUSTOMER_LIST';
export const RECEIVE_CUSTOMER_LIST = 'RECEIVE_CUSTOMER_LIST';
export const RECEIVE_CUSTOMER = 'RECEIVE_CUSTOMER';
export const SET_SELECTED_BILLING_CUSTOMER = 'SET_SELECTED_BILLING_CUSTOMER';
export const SET_SELECTED_SHIPPING_CUSTOMER = 'SET_SELECTED_SHIPPING_CUSTOMER';
export const SET_DROPSHIP_CONTACT = 'SET_DROPSHIP_CONTACT';
export const RESET_DROPSHIP_CONTACT = 'RESET_DROPSHIP_CONTACT';
export const RECEIVE_BILLING_CUSTOMER_ADDRESS =
  'RECEIVE_BILLING_CUSTOMER_ADDRESS';
export const RECEIVE_SHIPPING_CUSTOMER_ADDRESS =
  'RECEIVE_SHIPPING_CUSTOMER_ADDRESS';
export const RECEIVE_CUSTOMER_SHPPING_ADDRESS =
  'RECEIVE_CUSTOMER_SHPPING_ADDRESS';
export const OPEN_CUSTOMER_SHPPING_ADDRESS_SELECTOR =
  'OPEN_CUSTOMER_SHPPING_ADDRESS_SELECTOR';
export const CLOSE_CUSTOMER_SHPPING_ADDRESS_SELECTOR =
  'CLOSE_CUSTOMER_SHPPING_ADDRESS_SELECTOR';
export const OPEN_CUSTOMER_BILLING_ADDRESS_SELECTOR =
  'OPEN_CUSTOMER_BILLING_ADDRESS_SELECTOR';
export const CLOSE_CUSTOMER_BILLING_ADDRESS_SELECTOR =
  'CLOSE_CUSTOMER_BILLING_ADDRESS_SELECTOR';
export const SET_VOLTAGE = 'SET_VOLTAGE';
export const SET_BILLING_COUNTRY = 'SET_BILLING_COUNTRY';
export const SET_SHIPPING_CUSTOMERID = 'SET_SHIPPING_CUSTOMERID';
export const INIT_CUSTOMER_ADDRESS = 'INIT_CUSTOMER_ADDRESS';
export const SET_SELECTED_BILLING_ADDRESS = 'SET_SELECTED_BILLING_ADDRESS';
export const SET_SELECTED_SHIPPING_ADDRESS = 'SET_SELECTED_SHIPPING_ADDRESS';
export const INIT_SELECTED_ADDRESS = 'INIT_SELECTED_ADDRESS';
export const SET_BILLING_CUSTOMERID = 'SET_BILLING_CUSTOMERID';

export function initCustomerAdress() {
  return {
    type: INIT_CUSTOMER_ADDRESS,
  };
}

export function requestCustomerList(state) {
  return {
    type: REQUEST_CUSTOMER_LIST,
  };
}

export function receiveCustomerList(state, customerList) {
  return {
    type: RECEIVE_CUSTOMER_LIST,
    customerList: customerList,
  };
}

export function receiveCustomer(customer) {
  return {
    type: RECEIVE_CUSTOMER,
    customer: customer,
  };
}

export function setSelectedBillingCustomer(customer) {
  return {
    type: SET_SELECTED_BILLING_CUSTOMER,
    customer: customer,
  };
}

export function setSelectedShippingCustomer(customer) {
  return {
    type: SET_SELECTED_SHIPPING_CUSTOMER,
    customer: customer,
  };
}

export function receiveBillingCustomerAddress(state, customerAddress) {
  return {
    type: RECEIVE_BILLING_CUSTOMER_ADDRESS,
    customerAddress: customerAddress,
  };
}

export function receiveShippingCustomerAddress(state, customerAddress) {
  return {
    type: RECEIVE_SHIPPING_CUSTOMER_ADDRESS,
    customerAddress: customerAddress,
  };
}

export function receiveCustomerShppingAddress(state, customerShppingAddress) {
  return {
    type: RECEIVE_CUSTOMER_SHPPING_ADDRESS,
    customerShppingAddress: customerShppingAddress,
  };
}

export function openCustomerShppingAddressSelector() {
  return {
    type: OPEN_CUSTOMER_SHPPING_ADDRESS_SELECTOR,
    isOpen: true,
  };
}

export function closeCustomerShppingAddressSelector() {
  return {
    type: CLOSE_CUSTOMER_SHPPING_ADDRESS_SELECTOR,
    isOpen: false,
  };
}

export function openCustomerBillingAddressSelector() {
  return {
    type: OPEN_CUSTOMER_BILLING_ADDRESS_SELECTOR,
    isOpen: true,
  };
}

export function closeCustomerBillingAddressSelector() {
  return {
    type: CLOSE_CUSTOMER_BILLING_ADDRESS_SELECTOR,
    isOpen: false,
  };
}

export function setVoltage(voltage) {
  return {
    type: SET_VOLTAGE,
    voltage: voltage,
  };
}

export function setBillingCountry(billingCountry) {
  return {
    type: SET_BILLING_COUNTRY,
    billingCountry: billingCountry,
  };
}

export function setBillingCustomerID(ID) {
  return {
    type: SET_BILLING_CUSTOMERID,
    ID: ID,
  };
}

export function setShippingCustomerID(ID) {
  return {
    type: SET_SHIPPING_CUSTOMERID,
    ID: ID,
  };
}

export function setSelectedBillingAddress(address) {
  return {
    type: SET_SELECTED_BILLING_ADDRESS,
    address: address,
  };
}

export function setSelectedShippingAddress(address) {
  return {
    type: SET_SELECTED_SHIPPING_ADDRESS,
    address: address,
  };
}

export function setDropShipContact(contact) {
  return {
    type: SET_DROPSHIP_CONTACT,
    contact: contact,
  };
}

export const resetDropShipContact = () => ({
  type: RESET_DROPSHIP_CONTACT,
});

export function initSelectedAddress() {
  return {
    type: INIT_SELECTED_ADDRESS,
  };
}

export const getCustomerList = (state) => (dispatch) => {
  dispatch(requestCustomerList(state));
  return axios
    .get('/Cumtomer/getNameWithIDWithCurrency')
    .then((response) => {
      dispatch(receiveCustomerList(state, response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getCustomer = (customerID) => (dispatch) => {
  return axios
    .get('/Cumtomer', { params: { ID: customerID } })
    .then((response) => {
      dispatch(setSelectedBillingCustomer(response.data[0]));
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getShippingCustomer = (customerID) => (dispatch) => {
  return axios
    .get('/Cumtomer', { params: { ID: customerID } })
    .then((response) => {
      dispatch(setSelectedShippingCustomer(response.data[0]));
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getBillingCustomerAddress = (customerID) => (dispatch) => {
  return axios
    .get('/Cumtomer/getAddressUsingID', { params: { ID: customerID } })
    .then((response) => {
      dispatch(receiveBillingCustomerAddress(customerID, response.data[0]));
      dispatch(setVoltage(response.data[0].Voltage));
      dispatch(setBillingCountry(response.data[0].BillingCountry));
      dispatch(setBillingCustomerID(customerID));
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getShippingCustomerAddress = (customerID) => (dispatch) => {
  return axios
    .get('/Cumtomer/getAddressUsingID', { params: { ID: customerID } })
    .then((response) => {
      dispatch(receiveShippingCustomerAddress(customerID, response.data[0]));
      dispatch(setShippingCustomerID(customerID));
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getBillingCustomerDefaultAddress = (customerID) => (dispatch) => {
  return axios
    .get('/Account/getDefaultAddress', { params: { ID: customerID } })
    .then((response) => {
      dispatch(setSelectedBillingAddress(response.data.billingAddress));
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getShippingCustomerDefaultAddress = (customerID) => (dispatch) => {
  return axios
    .get('/Account/getDefaultAddress', { params: { ID: customerID } })
    .then((response) => {
      dispatch(setSelectedShippingAddress(response.data.shippingAddress));
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getDropShipContact =
  (accountId, dropShipContactId) => (dispatch) => {
    return axios
      .get(`/Contact/${accountId}`)
      .then((response) => {
        const contacts = response.data;
        const dropShipContact = contacts.find(
          (contact) => contact.id === dropShipContactId
        );
        dispatch(setDropShipContact(dropShipContact));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
