import React, { PureComponent, PropTypes } from 'react';
import axios from '../util/CustomAxios';

const withGetArrayData = (Component, url, mapper, urlParameter) => {
  return class extends PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        originalData: null,
      };
      if (mapper) {
        this.mapper = mapper.bind(this);
      }
    }

    componentWillMount() {
      // mapper = mapper.bind(this);
      axios
        .get(url, {
          params: urlParameter,
        })
        .then((response) => {
          this.setState({
            originalData: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    render() {
      const data = this.state.originalData
        ? this.mapper(this.state.originalData)
        : [];
      return <Component data={data} {...this.props} />;
    }
  };
};

export default withGetArrayData;
