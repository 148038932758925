import React, { Component } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import CountryWithFlag from './CountryWithFlag';
import TrueFalseSign from './TrueFalseSign';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button/Button';
import EditableCell from './EditableCell';
import SimpleDropdown from './SimpleDropdown';
import CountrySimpleDropdown from './CountrySimpleDropdown';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import SimpleMulipleDropdown from './SimpleMulipleDropdown';
import '../Account.css';
import ReactTableWithContextMenu from './ReactTableWithContextMenu';
import { AddressContextMenu } from './AddressContextMenu';

import { ReactTableDefaults } from 'react-table';
import ContactContextMenuContainer from '../Container/ContactContextMenuContainer';
import ContactInputModal from './ContactInputModal';
Object.assign(ReactTableDefaults, {
  NoDataComponent: () => <span></span>,
});

const ContextMenuTable = ReactTableWithContextMenu(ReactTable);

const emptyRow = [
  {
    name: '',
    email: '',
    title: '',
    phone: '',
    mobile_phone: '',
    title: '',
    note: '',
  },
];

export default class ContactList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newContact: {
        name: '',
        email: '',
        phone: '',
        mobile_phone: '',
        title: '',
        account_id: this.props.AccountID,
        note: '',
        isDefault: false,
      },
      validationError: {
        name: false,
      },
      contacts: [],
      showPopup: false,
    };

    this.onNewContactChange = this.onNewContactChange.bind(this);
    this.onContactChange = this.onContactChange.bind(this);
    this.onSubmitChangedContact = this.onSubmitChangedContact.bind(this);
    this.onNewContactSubmit = this.onNewContactSubmit.bind(this);
    this.togglePopup = this.togglePopup.bind(this);
  }

  componentDidMount() {
    this.setState({ contacts: this.props.contactList });
  }

  onSubmitChangedContact(row) {
    this.props.updateContact(
      this.state.contacts[row.index],
      this.state.contacts[row.index].id
    );
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ contacts: nextProps.contactList });
    if (this.props.AccountID != nextProps.AccountID) {
      this.setState({
        newContact: {
          name: '',
          email: '',
          phone: '',
          mobile_phone: '',
          title: '',
          account_id: nextProps.AccountID,
          note: '',
          isDefault: false,
        },
      });
    }
  }

  onContactChange(row, e) {
    const data = [...this.state.contacts];
    data[row.index][row.column.id] = e.target.value;
    this.setState({ contacts: data });
  }

  onNewContactChange(e) {
    // Remove validation error when name have valid value
    if (e.target.name === 'name') {
      if (e.target.value !== '') {
        this.setState({
          validationError: {
            ...this.state.validationError,
            name: false,
          },
        });
      }
    }
    const data = {};
    data[e.target.name] = e.target.value;
    this.setState({ newContact: { ...this.state.newContact, ...data } });
  }

  onNewContactSubmit() {
    if (this.state.newContact.name === '') {
      this.setState({
        validationError: {
          ...this.state.validationError,
          name: true,
        },
      });
      return;
    }
    this.props.onContactAddButtonOnclick(this.state.newContact);
    this.setState({
      newContact: {
        name: '',
        email: '',
        phone: '',
        mobile_phone: '',
        title: '',
        account_id: this.props.AccountID,
        note: '',
        isDefault: false,
      },
      showPopup: false,
    });
  }

  onDefaultContactChange = (row) => {
    this.props.updateContact(
      {
        ...this.state.contacts[row.index],
        default_contact_id: this.state.contacts[row.index].id,
      },
      this.state.contacts[row.index].id
    );
  };

  onNewDefaultContactChange = () => {
    this.setState({
      newContact: {
        ...this.state.newContact,
        isDefault: !this.state.newContact.isDefault,
      },
    });
  };

  togglePopup() {
    this.setState((prevState) => ({ showPopup: !prevState.showPopup }));
  }

  render() {
    const { contacts } = this.state;

    const { contactList } = this.props;

    const nomalCellGenerator = (row) =>
      row.original.isEdit ? (
        <input
          style={{ width: '100%' }}
          value={contacts[row.index][row.column.id]}
          onChange={(e) => this.onContactChange(row, e)}
        />
      ) : (
        <span>{row.value}</span>
      );

    return (
      <React.Fragment>
        <button
          onClick={this.togglePopup}
          style={{
            marginBottom: 20,
            width: 'fit-content',
          }}
        >
          Add New Contact ✚
        </button>
        <ContextMenuTable
          style={{ overflow: 'scroll' }}
          data={contactList ? [...contactList] : []}
          columns={[
            {
              Header: 'Default',
              Cell: (row) => (
                <input
                  type="checkbox"
                  checked={row.original.id == row.original.default_contact_id}
                  onClick={() => this.onDefaultContactChange(row)}
                />
              ),
              width: 100,
            },
            {
              Header: 'Name',
              accessor: 'name',
              Cell: nomalCellGenerator,
              width: 300,
            },
            {
              Header: 'Title',
              accessor: 'title',
              Cell: nomalCellGenerator,
              width: 250,
            },
            {
              Header: 'E-mail',
              accessor: 'email',
              Cell: nomalCellGenerator,
              width: 350,
            },
            {
              Header: 'Phone',
              accessor: 'phone',
              Cell: nomalCellGenerator,
              width: 300,
            },
            {
              Header: 'Note',
              accessor: 'note',
              Cell: nomalCellGenerator,
              width: 200,
            },
          ]}
          contextMenuComponent={ContactContextMenuContainer}
          contextMenuName="Contact"
          pageSize={this.props.contactList ? this.props.contactList.length : 0}
          showPagination={false}
          className="-striped -highlight"
          getTableProps={(state, rowInfo, column) => {
            return {
              style: {
                overflow: 'initial',
              },
            };
          }}
          getTfootTdProps={(state, rowInfo, column) => {
            return {
              style: {
                overflow: 'initial',
              },
            };
          }}
        />
        {this.state.showPopup && (
          <ContactInputModal
            newContact={this.state.newContact}
            validationError={this.state.validationError}
            onNewContactChange={this.onNewContactChange}
            onNewContactSubmit={this.onNewContactSubmit}
            onCancel={this.togglePopup}
          />
        )}
      </React.Fragment>
    );
  }
}
