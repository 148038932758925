import React from 'react';

const CustomerContactMenuItem = ({ name, phone, email, onClick }) => {
  return (
    <div className="CustomerAddress__Contents--Container" onClick={onClick}>
      <strong className="CustomerAddress__AccountName">{name}</strong>
      <p className={'CustomerAddress__Contents'}>{phone}</p>
      <p className={'CustomerAddress__Contents'}>{email}</p>
    </div>
  );
};

export default CustomerContactMenuItem;
