import React from 'react';
import { Component } from 'react';
import { Bar, defaults } from 'react-chartjs-2';
import ModelColor from '../ModelColor';

var MonthData = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

class ProductStatisticsBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }
  getIntMonthFromStringMonth(stringMonth) {
    let count = 1;
    let result = 0;
    MonthData.forEach((month) => {
      if (month == stringMonth) result = count;
      else count += 1;
    });
    return result;
  }

  chageDataAcordingToCurrency(dataArray, currency) {
    if (currency.trim() == 'KRW') {
      return dataArray.map((product) => {
        if (product.Currency.trim() == 'USD')
          return {
            ...product,
            Amount: product.Amount * 1060,
          };
        else if (product.Currency.trim() == 'CNY')
          return {
            ...product,
            Amount: Math.round(product.Amount * 167.84352),
          };
        else return product;
      });
    } else {
      return dataArray.map((product) => {
        if (product.Currency.trim() == 'KRW') {
          return {
            ...product,
            Amount: Math.round(Number(product.Amount / 1060)),
          };
        } else if (product.Currency.trim() == 'CNY')
          return {
            ...product,
            Amount: Math.round(product.Amount * 0.15903),
          };
        else return product;
      });
    }
  }
  makeBarChartData(dataArray, dataFromArray, currency) {
    let Amount = {};
    let Quantity = {};
    let AllData = { Amount: [], Quantity: [], Order: [] };

    dataFromArray.forEach((dataFrom) => {
      if (dataFrom.value != 'ALL') {
        eval(
          'Amount.' +
            dataFrom.value.trim().replace('-', '_').split(' ').join('') +
            '= new Array()'
        );
        eval(
          'Quantity.' +
            dataFrom.value.trim().replace('-', '_').split(' ').join('') +
            '= new Array()'
        );
        AllData.Order.push(dataFrom.value.trim());
        // eval("AllData." + dataFrom.value.trim().replace("-", "_").split(' ').join('') + "= {}")

        // eval("AllData." + dataFrom.value.trim().replace("-", "_").split(' ').join('') +".Amount"+ "= new Array()")
        // eval("AllData." + dataFrom.value.trim().replace("-", "_").split(' ').join('') +".Quantity"+ "= new Array()")
      }
    });
    this.chageDataAcordingToCurrency(dataArray, currency).forEach((data) => {
      let month = new Date(data.DateofShipment).getMonth();
      let index = 0;
      AllData.Order.some((model) => {
        if (data.Model.trim() == model) return true;
        else {
          index += 1;
          return false;
        }
      });

      if (
        eval(
          'Amount.' +
            data.Model.trim().replace('-', '_').split(' ').join('') +
            '[' +
            month +
            ']'
        ) == undefined
      )
        eval(
          'Amount.' +
            data.Model.trim().replace('-', '_').split(' ').join('') +
            '[' +
            month +
            '] =' +
            data.Amount
        );
      else
        eval(
          'Amount.' +
            data.Model.trim().replace('-', '_').split(' ').join('') +
            '[' +
            month +
            '] +=' +
            data.Amount
        );

      if (
        eval(
          'Quantity.' +
            data.Model.trim().replace('-', '_').split(' ').join('') +
            '[' +
            month +
            ']'
        ) == undefined
      )
        eval(
          'Quantity.' +
            data.Model.trim().replace('-', '_').split(' ').join('') +
            '[' +
            month +
            '] =' +
            data.Quantity
        );
      else
        eval(
          'Quantity.' +
            data.Model.trim().replace('-', '_').split(' ').join('') +
            '[' +
            month +
            '] +=' +
            data.Quantity
        );

      if (AllData.Amount[index] == undefined)
        AllData.Amount[index] = data.Amount;
      else AllData.Amount[index] += data.Amount;
      if (AllData.Quantity[index] == undefined)
        AllData.Quantity[index] = data.Quantity;
      else AllData.Quantity[index] += data.Quantity;
    });
    return { Amount, Quantity, AllData };
  }
  toDataFormat(data) {
    let formattedData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    if (data != undefined) {
      for (let index = 0; index < data.length; index++) {
        formattedData[index] = data[index] == null ? 0 : data[index];
      }
    }
    return formattedData;
  }
  componentWillMount() {
    this.props.getSelectedBar(13, 'ALL');
    this.setState({
      data: this.makeBarChartData(
        this.props.data,
        this.props.dataFrom,
        this.props.currency
      ),
    });
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.data != nextProps.data ||
      this.props.currency != nextProps.currency
    ) {
      this.setState({
        data: this.makeBarChartData(
          nextProps.data,
          nextProps.dataFrom,
          nextProps.currency
        ),
      });
    }
  }
  render() {
    let chartData = {};
    let chartOption = {};
    let modelColor = ModelColor;

    if (this.props.type == 'Quantity') {
      if (
        this.props.model.trim().replace('-', '_').split(' ').join('') != 'ALL'
      ) {
        let color = eval(
          'modelColor.' +
            this.props.model.trim().replace('-', '_').split(' ').join('')
        );
        chartData = {
          labels: MonthData,
          datasets: [
            {
              label: 'Quantity',
              backgroundColor: color + '0.6)',
              borderColor: color + '0.8)',
              borderWidth: 1,
              hoverBackgroundColor: color + '0.75)',
              hoverBorderColor: color + '1)',
              data: this.toDataFormat(
                eval(
                  'this.state.data.Quantity.' +
                    this.props.model
                      .trim()
                      .replace('-', '_')
                      .split(' ')
                      .join('')
                )
              ),
            },
          ],
        };
        chartOption = {
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                stacked: false,
              },
            ],
            yAxes: [
              {
                stacked: false,
                ticks: {
                  callback: function (label, index, labels) {
                    if (label < 1000) return label;
                    else if (1000 <= label && label < 1000000)
                      return label / 1000 + 'K';
                    else if (1000000 <= label && label < 1000000000)
                      return label / 1000000 + 'M';
                    else if (1000000000 <= label && label < 1000000000000)
                      return label / 1000000000 + 'G';
                    else return label;
                  },
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                var label = data.datasets[tooltipItem.datasetIndex].label || '';

                if (label) {
                  label += ' : ';
                }
                label += new Intl.NumberFormat().format(
                  Math.round(tooltipItem.yLabel * 100) / 100
                );
                return label;
              },
            },
          },
        };
      } else {
        let dataset = [];
        let index = 0;
        this.state.data.AllData.Order.forEach((model) => {
          let color = eval(
            'modelColor.' + model.trim().replace('-', '_').split(' ').join('')
          );
          dataset.push({
            label: model,
            backgroundColor: color + '0.6)',
            borderColor: color + '0.8)',
            borderWidth: 1,
            hoverBackgroundColor: color + '0.75)',
            hoverBorderColor: color + '1)',
            data: [this.state.data.AllData.Quantity[index]],
          });
          index += 1;
        });
        chartData = {
          labels: ['Quantity'],
          datasets: dataset,
        };
        chartOption = {
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                stacked: false,
              },
            ],
            yAxes: [
              {
                stacked: false,
                ticks: {
                  callback: function (label, index, labels) {
                    if (label < 1000) return label;
                    else if (1000 <= label && label < 1000000)
                      return label / 1000 + 'K';
                    else if (1000000 <= label && label < 1000000000)
                      return label / 1000000 + 'M';
                    else if (1000000000 <= label && label < 1000000000000)
                      return label / 1000000000 + 'G';
                    else return label;
                  },
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                var label = data.datasets[tooltipItem.datasetIndex].label || '';

                if (label) {
                  label += ' : ';
                }
                label += new Intl.NumberFormat().format(
                  Math.round(tooltipItem.yLabel * 100) / 100
                );
                return label;
              },
            },
          },
        };
      }
    } else if (this.props.type == 'Amount') {
      let currencyMark = '';

      if (this.props.currency.trim() == 'KRW') currencyMark = '￦';
      else if (this.props.currency.trim() == 'USD') currencyMark = '$';
      else currencyMark = '$';

      if (
        this.props.model.trim().replace('-', '_').split(' ').join('') != 'ALL'
      ) {
        let color = eval(
          'modelColor.' +
            this.props.model.trim().replace('-', '_').split(' ').join('')
        );
        chartData = {
          labels: MonthData,
          datasets: [
            {
              label: 'Amount',
              backgroundColor: color + '0.6)',
              borderColor: color + '0.8)',
              borderWidth: 1,
              hoverBackgroundColor: color + '0.75)',
              hoverBorderColor: color + '1)',
              data: this.toDataFormat(
                eval(
                  'this.state.data.Amount.' +
                    this.props.model
                      .trim()
                      .replace('-', '_')
                      .split(' ')
                      .join('')
                )
              ),
            },
          ],
        };
        chartOption = {
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                stacked: false,
              },
            ],
            yAxes: [
              {
                stacked: false,
                ticks: {
                  callback: function (label, index, labels) {
                    if (label < 1000) return currencyMark + '' + label;
                    else if (1000 <= label && label < 1000000)
                      return currencyMark + '' + label / 1000 + 'K';
                    else if (1000000 <= label && label < 1000000000)
                      return currencyMark + '' + label / 1000000 + 'M';
                    else if (1000000000 <= label && label < 1000000000000)
                      return currencyMark + '' + label / 1000000000 + 'G';
                    else return currencyMark + '' + label;
                  },
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                var label = data.datasets[tooltipItem.datasetIndex].label || '';

                if (label) {
                  label += ' : ';
                }
                label +=
                  currencyMark +
                  '' +
                  new Intl.NumberFormat().format(
                    Math.round(tooltipItem.yLabel * 100) / 100
                  );
                return label;
              },
            },
          },
        };
      } else {
        let dataset = [];
        let index = 0;
        this.state.data.AllData.Order.forEach((model) => {
          let color = eval(
            'modelColor.' + model.trim().replace('-', '_').split(' ').join('')
          );
          dataset.push({
            label: model,
            backgroundColor: color + '0.6)',
            borderColor: color + '0.8)',
            borderWidth: 1,
            hoverBackgroundColor: color + '0.75)',
            hoverBorderColor: color + '1)',
            data: [this.state.data.AllData.Amount[index]],
          });
          index += 1;
        });
        chartData = {
          labels: ['Amount'],
          datasets: dataset,
        };
        chartOption = {
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                stacked: false,
              },
            ],
            yAxes: [
              {
                stacked: false,
                ticks: {
                  callback: function (label, index, labels) {
                    if (label < 1000) return currencyMark + '' + label;
                    else if (1000 <= label && label < 1000000)
                      return currencyMark + '' + label / 1000 + 'K';
                    else if (1000000 <= label && label < 1000000000)
                      return currencyMark + '' + label / 1000000 + 'M';
                    else if (1000000000 <= label && label < 1000000000000)
                      return currencyMark + '' + label / 1000000000 + 'G';
                    else return currencyMark + '' + label;
                  },
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                var label = data.datasets[tooltipItem.datasetIndex].label || '';

                if (label) {
                  label += ' : ';
                }
                label +=
                  currencyMark +
                  '' +
                  new Intl.NumberFormat().format(
                    Math.round(tooltipItem.yLabel * 100) / 100
                  );
                return label;
              },
            },
          },
        };
      }
    }

    return (
      <div>
        <Bar
          data={chartData}
          options={chartOption}
          width={100}
          height={250}
          getElementAtEvent={(elems) => {
            console.log(elems);

            if (elems.length > 0) {
              if (this.props.model != 'ALL')
                this.props.getSelectedBar(
                  this.getIntMonthFromStringMonth(elems[0]._model.label),
                  this.props.model
                );
              else this.props.getSelectedBar(13, elems[0]._model.datasetLabel);
            }
          }}
        />
      </div>
    );
  }
}

export default ProductStatisticsBar;
