export default {
	  host: "https://aurenderoprapps.com",
	  graphConfig: {
		      clientID: "5f1f5902-020d-429b-9a83-cc36e6f035b2",
		      redirectUri: "http://192.168.5.6:3000/",
		      interactionMode: "popUp",
		      graphEndpoint: "https://graph.microsoft.com/v1.0/me",
		      graphScopes: ["user.read mail.send"],
		    },
	  };

