export default {
  AurenderInc: {
    byCountry: [
      {
        Country: 'China (CN)',
        Prices: [
          { Model: 'W20', HDD: '12T', Amount: 9680 },
          { Model: 'N10', HDD: '4T', Amount: 4400 },
          { Model: 'A10', HDD: '4T', Amount: 3050 },
          { Model: 'X100L', HDD: '12T', Amount: 2145 },
          { Model: 'N100H', HDD: '2T', Amount: 1485 },
          { Model: 'N100C', HDD: '4T', SSD: '240G', Amount: 1990 },
          { Model: 'UT100', HDD: 'None', Amount: 200 },
          { Model: 'UC100', HDD: 'None', Amount: 400 },
          { Model: 'S5W', HDD: 'None', Amount: 1300 },
        ],
      },
    ],
    byCompany: [
      {
        //GLV
        Company: 140,
        Prices: [
          { Model: 'W20', HDD: '12T', Amount: 7200000 },
          { Model: 'N10', HDD: '4T', Amount: 3600000 },
          { Model: 'N10', HDD: '8T', Amount: 3870000 },
          { Model: 'A10', HDD: '4T', Amount: 2790000 },
          { Model: 'X100L', HDD: '12T', Amount: 1620000 },
          { Model: 'N100H', HDD: '2T', Amount: 1170000 },
          { Model: 'N100C', HDD: '4T', SSD: '240G', Amount: 1620000 },
          { Model: 'X725', HDD: 'None', Amount: 900000 },
          { Model: 'UT100', HDD: 'None', Amount: 180000 },
          { Model: 'UC100', HDD: 'None', Amount: 350000 },
        ],
      },
    ],
  },
  AurenderAmericaInc: {},
};
