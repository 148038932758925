import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Container,
  Header,
  Segment,
  Grid,
  Dropdown,
  Tab,
} from 'semantic-ui-react';

import AddressListContainer from './Container/AddressListContainer';
import TrueFalseSign from './Presenter/TrueFalseSign';
import ContactListContainer from './Container/ContactListContainer';
import AccountTypeDropDownContainer from './Container/AccountTypeDropDownContainer';
import AccountStatusContainer from './Container/AccountStatusContainer';
import Statistics_BarTable from '../Statistics/Statistics_BarTable';

const TypeData = [
  {
    text: 'Amount',
    value: 'Amount',
  },
  {
    text: 'Sales Order',
    value: 'Sales Order',
  },
];

class AccountDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      year: new Date().getFullYear(),

      yearData: [],
      selectedYear: null,
      typeData: TypeData,
      selectedType: TypeData[0].value,
      // currencyData: CurrencyData,
      // selectedCurrency: localStorage.getItem('Company') == "Aurender Inc." ? "KRW" : "USD",
    };
    this.yearDropDownOnChangeHandler =
      this.yearDropDownOnChangeHandler.bind(this);
    this.typeDropDownOnChangeHandler =
      this.typeDropDownOnChangeHandler.bind(this);
    // this.currencyDropDownOnChangeHandler = this.currencyDropDownOnChangeHandler.bind(this);
  }
  makeYearData() {
    let startYear = 2016;
    let nowYear = new Date().getFullYear();
    let YearData = [];
    for (let index = startYear; index <= nowYear; index++) {
      YearData.push({
        text: `${index}`,
        value: index,
      });
    }
    return YearData;
  }
  yearDropDownOnChangeHandler(event, data) {
    this.setState({
      selectedYear: data.value,
    });
  }

  typeDropDownOnChangeHandler(event, data) {
    this.setState({
      selectedType: data.value,
    });
  }

  // currencyDropDownOnChangeHandler(event, data) {
  //     this.setState({
  //         selectedCurrency: data.value
  //     })
  // }

  componentWillMount() {
    this.setState({
      yearData: this.makeYearData(),
      selectedYear: new Date().getFullYear(),
    });
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps);
  }

  render() {
    const panes = [
      {
        menuItem: 'Info',
        render: () => (
          <Tab.Pane attached={true}>
            <Grid columns="equal" stackable>
              <Grid.Row stretched>
                <Grid.Column width={3} verticalAlign="middle">
                  <Header as="h4">Type : </Header>
                </Grid.Column>
                <Grid.Column width={5}>
                  <div style={{ display: 'inline-block' }}>
                    <AccountTypeDropDownContainer
                      value={{
                        key: this.props.TypeID,
                        value: this.props.TypeID,
                        text: this.props.Type,
                      }}
                      AccountID={this.props.AccountID}
                    />
                  </div>
                </Grid.Column>
                <Grid.Column width={3} verticalAlign="middle">
                  <Header as="h4">Status : </Header>
                </Grid.Column>
                <Grid.Column width={5}>
                  <div style={{ display: 'inline-block' }}>
                    <AccountStatusContainer
                      value={this.props.Status}
                      AccountID={this.props.AccountID}
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row stretched>
                <Grid.Column width={3} verticalAlign="middle">
                  <Header as="h4">Contact</Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row stretched>
                <Grid.Column width={16} verticalAlign="middle">
                  <ContactListContainer AccountID={this.props.AccountID} />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row stretched>
                <Grid.Column width={3} verticalAlign="middle">
                  <Header as="h4">Address</Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row stretched>
                <Grid.Column width={16} verticalAlign="middle">
                  <AddressListContainer AccountID={this.props.AccountID} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Graph',
        render: () => (
          <Tab.Pane attached={true}>
            <Grid columns="equal" stackable>
              <Grid.Row stretched>
                <Grid.Column width={16}>
                  <Statistics_BarTable
                    isTestShow={this.state.isTestShow}
                    AccountID={this.props.AccountID}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Tab.Pane>
        ),
      },
    ];

    return (
      <Container>
        <Header as="h2" dividing>
          <br />
          <Header.Content>{this.props.Name}</Header.Content>
        </Header>
        <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
      </Container>
    );
  }
}

export default AccountDetail;
