import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Segment, List } from 'semantic-ui-react';
import ProductSuggestBoxItem from './ProductSuggestBoxItem';

const propTypes = {
  productList: PropTypes.arrayOf(
    PropTypes.shape({
      ProductCode: PropTypes.string,
      ProductID: PropTypes.number.isRequired,
      ModelT: PropTypes.string,
      Model: PropTypes.string,
      Color: PropTypes.string,
      Voltage: PropTypes.string,
      HDD: PropTypes.string,
      SSD: PropTypes.string,
      USDRetail: PropTypes.number,
      KRWRetail: PropTypes.number,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
};

const defaultProps = {
  productList: [],
  onSelect: createWarning('onItemClick'),
};

function createWarning(funcName) {
  return () => console.warn((funcName = ' is not defined'));
}

export default class ProductSuggestBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocused: false,
      model: '',
      color: '',
      voltage: '',
      hdd: '',
      ssd: '',
    };

    this.searchInputOnFocusHandler = this.searchInputOnFocusHandler.bind(this);
    this.searchInputOnFocusOutHandler =
      this.searchInputOnFocusOutHandler.bind(this);
    this.inputOnChangeHandler = this.inputOnChangeHandler.bind(this);
    this.listItemOnClickHandler = this.listItemOnClickHandler.bind(this);
  }

  searchInputOnFocusHandler() {
    this.setState({
      isFocused: true,
    });
  }
  searchInputOnFocusOutHandler(event) {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      this.setState({
        isFocused: false,
      });
    }
  }

  inputOnChangeHandler(event) {
    this.setState({
      [event.target.name]: event.target.value.trim(),
    });
  }

  listItemOnClickHandler(selectedItem) {
    this.setState({
      model: selectedItem.Model.trim(),
      color: selectedItem.Color.trim(),
      voltage: selectedItem.Voltage.trim(),
      hdd: selectedItem.HDD.trim(),
      ssd: selectedItem.SSD.trim(),
      isFocused: false,
    });
    this.props.onSelect(selectedItem);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.voltage.trim() != '') {
      if (this.state.voltage != nextProps.voltage.trim()) {
        this.setState({
          voltage: nextProps.voltage.trim(),
        });
      }
    }
  }

  render() {
    const SuggestOptions = this.props.productList
      .filter((product) => {
        if (
          this.state.model.toLowerCase() != '' &&
          (!product.Model ||
            product.Model.toLowerCase().indexOf(
              this.state.model.toLowerCase()
            ) == -1)
        )
          return false;
        if (
          this.state.color.toLowerCase() != '' &&
          (!product.Color ||
            product.Color.toLowerCase().indexOf(
              this.state.color.toLowerCase()
            ) == -1)
        )
          return false;
        if (
          this.state.voltage.toLowerCase() != '' &&
          (!product.Voltage ||
            (!product.Voltage.toLowerCase().startsWith(
              this.state.voltage.toLowerCase()
            ) &&
              !product.Voltage.startsWith('Free Voltage')))
        )
          return false;
        if (
          this.state.hdd.toLowerCase() != '' &&
          (!product.HDD ||
            product.HDD.toLowerCase().indexOf(this.state.hdd.toLowerCase()) ==
              -1)
        )
          return false;
        if (
          this.state.ssd.toLowerCase() != '' &&
          (!product.SSD ||
            product.SSD.toLowerCase().indexOf(this.state.ssd.toLowerCase()) ==
              -1)
        )
          return false;
        return true;
      })
      .map((product, index) => (
        <ProductSuggestBoxItem
          key={index}
          product={product}
          onClick={this.listItemOnClickHandler}
        />
      ));

    return (
      <div
        onBlur={this.searchInputOnFocusOutHandler}
        style={{ position: 'relative' }}
      >
        <div
          className="ui input"
          ref={(input) => {
            this.aa = input;
          }}
        >
          <input
            type="text"
            value={this.state.model}
            name="model"
            placeholder="Search..."
            style={{ width: '20%' }}
            onFocus={this.searchInputOnFocusHandler}
            onChange={this.inputOnChangeHandler}
          />
          <input
            type="text"
            value={this.state.color}
            name="color"
            placeholder="Search..."
            style={{ width: '20%' }}
            onFocus={this.searchInputOnFocusHandler}
            onChange={this.inputOnChangeHandler}
          />
          <input
            type="text"
            value={this.state.voltage}
            name="voltage"
            placeholder="Search..."
            style={{ width: '20%' }}
            onFocus={this.searchInputOnFocusHandler}
            onChange={this.inputOnChangeHandler}
          />
          <input
            type="text"
            value={this.state.hdd}
            name="hdd"
            placeholder="Search..."
            style={{ width: '20%' }}
            onFocus={this.searchInputOnFocusHandler}
            onChange={this.inputOnChangeHandler}
          />
          <input
            type="text"
            value={this.state.ssd}
            name="ssd"
            placeholder="Search..."
            style={{ width: '20%' }}
            onFocus={this.searchInputOnFocusHandler}
            onChange={this.inputOnChangeHandler}
          />
        </div>
        {this.state.isFocused ? (
          <Segment
            style={{
              position: 'absolute',
              margin: 0,
              width: '100%',
              zIndex: 1,
              height: '200px',
              overflow: 'scroll',
            }}
          >
            <List divided selection tabIndex={0}>
              {SuggestOptions}
            </List>
          </Segment>
        ) : null}
      </div>
    );
  }
}

ProductSuggestBox.PropTypes = propTypes;
ProductSuggestBox.defaultProps = defaultProps;
