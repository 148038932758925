import React from 'react';
import { Input, Grid } from 'semantic-ui-react';
import { ColumnLabel, LabelInput } from './customComponents';
import withReduxFormValidation from '../../../HOCs/withReduxFormValidation';

const SalesOrderDate = (props) => {
  return <Input {...props} type="date" />;
};

export default withReduxFormValidation(SalesOrderDate);
