import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import withReduxFormValidation from '../../../HOCs/withReduxFormValidation';

const CustomerDrobox = (props) => {
  const dropdownOptions = props.customerList.map((customer, index) => {
    return {
      value: customer.AccountID,
      key: customer.AccountID,
      text: customer.Name,
    };
  });

  return (
    <Dropdown
      {...props}
      placeholder="Customer"
      fluid
      search
      selection
      options={dropdownOptions}
      value={props.input.value}
      onChange={props.onChange}
    />
  );
};

export default withReduxFormValidation(CustomerDrobox);
