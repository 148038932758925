import React from 'react';
import { Header } from 'semantic-ui-react';

class DropShipContactInputModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      phone: '',
      mobile_phone: '',
      email: '',
      note: '',
      title: '',
    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = () => {
    const { name, phone, mobile_phone, email, note, title } = this.state;
    const { onDropShipContactSubmit } = this.props;
    onDropShipContactSubmit({
      name,
      phone,
      mobile_phone,
      email,
      note,
      title,
    });
  };

  render() {
    const { name, phone, email, note, mobile_phone } = this.state;
    const { onCancel } = this.props;
    return (
      <div style={styles.popup}>
        <div style={styles.popupInner}>
          <Header
            as="h5"
            dividing
            style={{
              marginTop: '1rem',
            }}
          >
            DropShip Contact Info
          </Header>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.handleSubmit();
            }}
          >
            <div style={styles.formGroup}>
              <label style={styles.label}>Name</label>
              <input
                style={styles.input}
                type="text"
                name="name"
                value={name}
                onChange={this.handleInputChange}
              />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Phone</label>
              <input
                style={styles.input}
                type="text"
                name="phone"
                value={phone}
                onChange={this.handleInputChange}
              />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Mobile Phone</label>
              <input
                style={styles.input}
                type="text"
                name="mobile_phone"
                value={mobile_phone}
                onChange={this.handleInputChange}
              />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Email</label>
              <input
                style={styles.input}
                type="text"
                name="email"
                value={email}
                onChange={this.handleInputChange}
              />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Note</label>
              <input
                style={styles.input}
                type="text"
                name="note"
                value={note}
                onChange={this.handleInputChange}
              />
            </div>
            <button
              style={styles.button}
              type="submit"
              onClick={this.handleSubmit}
            >
              Add
            </button>
            <button style={styles.button} type="button" onClick={onCancel}>
              Cancel
            </button>
          </form>
        </div>
      </div>
    );
  }
}

const styles = {
  popup: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  popupInner: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '5px',
    maxWidth: '500px',
    width: '100%',
  },
  formGroup: {
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
  },
  input: {
    width: '100%',
    padding: '5px',
    fontSize: '14px',
    border: '1px solid #ccc',
  },
  button: {
    marginRight: '10px',
    padding: '5px 10px',
    fontSize: '14px',
    cursor: 'pointer',
  },
};

export default DropShipContactInputModal;
