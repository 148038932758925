import { SET_STATE_LIST } from '../action/State';

export const stateList = (state = [], action) => {
  switch (action.type) {
    case SET_STATE_LIST:
      return action.stateList;
    default:
      return state;
  }
};
