import {
  SET_ACCOUNT_LIST,
  ADD_ADDRESS,
  INSERT_ADDRESS,
  SET_EDIT_MODE_ADDRESS,
  CHANGE_ACCOUNT,
} from '../action/account';

export const addressList = (state = {}, action) => {
  switch (action.type) {
    case ADD_ADDRESS:
      const newAddress = {};
      newAddress[action.accountId] = action.addressList;
      return {
        ...state,
        ...newAddress,
      };
    case INSERT_ADDRESS:
      return state[action.address.account_id.toString()].push(action.address);
    case SET_EDIT_MODE_ADDRESS:
      for (
        let i = 0;
        i < state[action.address.account_id.toString()].length;
        i++
      ) {
        if (
          state[action.address.account_id.toString()][i].id == action.address.id
        ) {
          state[action.address.account_id.toString()][i].isEdit = true;
        }
      }
      return { ...{}, ...state };
    default:
      return state;
  }
};

export const accountList = (state = [], action) => {
  switch (action.type) {
    case SET_ACCOUNT_LIST:
      return action.accountList;
    case CHANGE_ACCOUNT:
      state.forEach((account, index) => {
        if (account.AccountID === action.accountId) {
          state[index] = {
            ...state[index],
            ...action.changedPart,
          };
        }
      });
      return [...[], ...state];
    default:
      return state;
  }
};
